import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss'],
})
export class GruposComponent implements OnInit {

  grupos = [];

  user_id;
  token;

  loading_global = false;
  is_desktop = false;
  regulamento = false;
  constructor(private location:Location, private http:HttpClient, private router:Router, private route:ActivatedRoute) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.token = localStorage.getItem("token");
  
    if (!this.user_id || !this.token) {
      this.router.navigate(['/login']);
    }
  
    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_grupos();
  }

  goback(){
    this.location.back();
  }

  obter_grupos(){
    let formData = new FormData();

    this.loading_global = true;

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_grupos'), formData).subscribe(
      data => {
        if(data.status == 0){
          alert("Erro: "+data.error);
        }
        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }

        if(data.status == 1){
          this.grupos = data.grupos;
        }

        this.loading_global = false;
      }
    )
  }


  entrar(grupo){

    let formData = new FormData();
    this.loading_global = true;

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    formData.append('id_grupo', grupo.id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/entrar_grupo'), formData).subscribe(
      data => {

        if(data.status == 0){
          alert("Erro: "+data.error);
        }

        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }

        this.loading_global = false;

        if(data.status == 1){
          this.obter_grupos();
        }
      }
    )

  }

}
