import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-bloco',
  templateUrl: 'bloco.component.html',
  styleUrls: ['bloco.component.css'],
})
export class Bloco {
  @Input()
  rootClassName: string = ''
  @Input()
  image_src: string = '/assets/external/ilustracao-1400w.jpg'
  @Input()
  image_alt: string = 'image'
  @Input()
  text: string =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lacinia molestie ex at blandit. Sed vulputate libero vel metus posuere, vitae porta tortor fringilla. Nunc vel est in massa hendrerit sagittis non eu ante. Interdum et malesuada fames ac ante ipsum primis in faucibus'
  @Input()
  button: string = 'Learn More'
  @Input()
  heading: string = 'Heading'
  constructor() {}
}
