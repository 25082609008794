import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-aulas',
  templateUrl: './aulas.component.html',
  styleUrls: ['./aulas.component.scss'],
})

export class AulasComponent implements OnInit {

  @Input() curso: any = [];
  @Output() finalizar = new EventEmitter();

  moduloAtivo = null;
  aulaSelecionada: any = null;
  respostas = [];
  acertou: any;
  etapa = 1;
  safeUrl:SafeResourceUrl;
  loading = false;
  user_id;
  bloqueado = false; 
  concluido = false;
  isExpanded = true;

  constructor(private sanitizer: DomSanitizer, private http:HttpClient) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.configurar_aulas();
    this.moduloAtivo = this.curso.modulos[0];
  }

  configurar_aulas() {
     for (const modulo of this.curso.modulos) {
      for (const aula of modulo.aulas) {

        if (aula.url_content) {
          aula.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(aula.url_content);
        }

        const respondeu = aula.participantes.find((reg) => reg.id_participante === this.user_id);
        if (respondeu) {
          aula.statusText = 'Concluído';
        }
        else{
          aula.statusText = 'Pendente';
        }
      }
      
    } 
  }


  timer_bloqueio;

  openAula(aula) {
    this.concluido = false;
    this.aulaSelecionada = aula;
    let participacao = aula.participantes.find(p => p.id_participante == this.user_id);
  
    const data_atual = new Date();
    let timer_bloqueio:any = localStorage.getItem('timer_bloqueio');
    timer_bloqueio = timer_bloqueio ? new Date(timer_bloqueio) : null;
  
    if (participacao) {
      this.concluido = true;
      this.bloqueado = true;
    } else if (timer_bloqueio && data_atual < timer_bloqueio) {
      this.timer_bloqueio = timer_bloqueio.getTime() - data_atual.getTime();
      console.log(this.timer_bloqueio);
      this.bloqueado = true;
    } else {
      this.bloqueado = false;
      this.reset_timer(); // Assegure-se de que esta função reseta corretamente o timer ou remove do localStorage
    }
  }

  reset_timer(){
    this.etapa = 1;
    this.bloqueado = true;
    setTimeout(()=>{
      this.bloqueado = false;
    }, 1000);
  }

  set_modulo(modulo){
    this.etapa = 1;
    this.moduloAtivo = modulo;
    this.aulaSelecionada = null;
    this.isExpanded = false;
  }

  toggleModuleDisplay(): void {
    this.isExpanded = !this.isExpanded;
  }

  set_resposta(ordem, index){
    this.respostas[index] = ordem;
  }

  responder_quiz() {
    if (this.aulaSelecionada.pergunta.length > this.respostas.length) {
      alert("Responda todas as perguntas");
      return;
    }

    this.loading = true;

    this.http.post<any>(environment.API_URL.concat('cursos_iclub/responder_quiz/'), {
      id_participante: this.user_id,
      id_aula: this.aulaSelecionada.id,
      respostas: this.respostas
    }).subscribe(data => {
      this.acertou = data.acertou;
      this.etapa = 2;
      
      if (data.acertou === 'S') {
        let efeito_sonoro = new Audio("assets/audio/positivo.wav");
        efeito_sonoro.play();
        this.finalizar.emit({ modulo: this.moduloAtivo, aula: this.aulaSelecionada, pontos: data.pontos ?? 0 });

        setTimeout(() => {
          this.configurar_aulas();
        }, 1000);
      } else {
        let efeito_sonoro = new Audio("assets/audio/resposta_errada.wav");
        efeito_sonoro.play();
        this.definir_timer_bloqueio();      
      }
      
      this.respostas = [];
      this.loading = false;
    });
  }

  definir_timer_bloqueio(){
    const data_atual = new Date();
    data_atual.setHours(data_atual.getHours() + 1);
    localStorage.setItem('timer_bloqueio', data_atual.toISOString());
    this.timer_bloqueio = 3587000;
  }


}
