import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotores',
  templateUrl: './promotores.component.html',
  styleUrls: ['./promotores.component.scss'],
})
export class PromotoresComponent implements OnInit {

  user_id;
  pontos;
  perfil = parseInt(localStorage.getItem('perfil'));
  posicao;
  progress: number = 30;

  ranking:any = [];


  is_desktop = false;

  data = new Date();
  regulamento = false;
  modal = false;
  
  constructor( private http:HttpClient, private router:Router, private location:Location, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    
    if(this.perfil === 1){
      this.router.navigate(['/home']);
    }

    if(!this.user_id){
     this.router.navigate(['/login']);
    }
    
    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }


    this.obter_ranking();
  }

  
  abrir_regulamento(){
    this.regulamento = true;
  }


  obter_ranking() {
    let url = 'participantes_iclub/obter_ranking/'+this.perfil+'/1000';
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking = data.slice(0, 5);
      let indice = data.findIndex(item => item.id === this.user_id);
      this.posicao = indice+1;
    });
  }
  

  goback(){
    this.location.back();
  }

}
