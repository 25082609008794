import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grupo-header',
  templateUrl: './grupo-header.component.html',
  styleUrls: ['./grupo-header.component.scss'],
})
export class GrupoHeaderComponent implements OnInit {
  
  user_id;
  group_name;

  @Input() id_grupo;
  grupo:any = [];

  public foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
  public imageSrc = '/assets/images/imagem-perfil.png';
  showOptions = false;

  constructor(private router: Router, private http: HttpClient, private location:Location) {  }

  ngOnInit() {

    let foto_src = localStorage.getItem('foto_src');
    if(foto_src){
      this.foto_src = foto_src;
    }

    this.user_id = localStorage.getItem('userId');
    this.obter_grupo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  obter_grupo(){
    let formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_grupos'), formData).subscribe(
      data => {

        console.log(data);
        if(data.status == 0){
          alert("Erro: "+data.error);
        }
        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }

        if(data.status == 1){
          this.grupo = data.grupos.find(g => g.id == this.id_grupo );
          console.log(this.grupo);
        }
      }
    )
  }

  sair(){

    let formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);
    formData.append('id_grupo', this.id_grupo);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/sair_grupo'), formData).subscribe(
      data => {

        console.log(data);
        if(data.status == 0){
          alert("Erro: "+data.error);
        }
        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }

        if(data.status == 1){
          this.router.navigate(['/home']);
        }
      }
    )

  }

  goback(){
    this.location.back();
  }

}
