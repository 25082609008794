import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { CadastroComponent } from './paginas/cadastro/cadastro.component';
import { LoginComponent } from './paginas/login/login.component';
import { IndexComponent } from './paginas/index/index.component';
import { HeaderComponent } from './includes/header/header.component';
import { PopupVideoComponent } from './includes/popup-video/popup-video.component';
import { PerfilComponent } from './includes/perfil/perfil.component';
import { FooterComponent } from './includes/footer/footer.component';
import { ExtratoComponent } from './paginas/extrato/extrato.component';
import { PrimeiroAcessoComponent } from './paginas/primeiro-acesso/primeiro-acesso.component';
import { PerfilUsuarioComponent } from './paginas/perfil-usuario/perfil-usuario.component';
import { ConfirmacaoComponent } from './paginas/confirmacao/confirmacao.component';
import { RegulamentomodalComponent } from './includes/regulamentomodal/regulamentomodal.component';
import { DescadastrarComponent } from './paginas/descadastrar/descadastrar.component';
import { FaqComponent } from './paginas/faq/faq.component';
import { ContagemRegressivaComponent } from './includes/contagem_regressiva/contagem_regressiva.component';
import { DescontosComponent } from './paginas/descontos/descontos.component';
import { RankingComponent } from './paginas/ranking/ranking.component';
import { LoaderComponent } from './includes/loader/loader.component';
import { LandingComponent } from './paginas/landing/landing.component';
import { LandingMobileComponent } from './paginas/landing-mobile/landing-mobile.component';
import { HomeSocialComponent } from './paginas/home-social/home-social.component';
import { ComentariosComponent } from './includes/comentarios/comentarios.component';
import { MenuInferiorComponent } from './includes/menu-inferior/menu-inferior.component';
import { HeaderUserComponent } from './includes/header-user/header-user.component';
import { HeaderUserPerfilComponent } from './includes/header-user-perfil/header-user-perfil.component';
import { GruposComponent } from './paginas/grupos/grupos.component';
import { GrupoHeaderComponent } from './includes/grupo-header/grupo-header.component';
import { EscolaComponent } from './paginas/escola/escola.component';
import { EnqueteComponent } from './paginas/enquete/enquete.component';
import { AniversariantesComponent } from './paginas/aniversariantes/aniversariantes.component';
import { DriveComponent } from './paginas/drive/drive.component';
import { AniversarianteComponent } from './paginas/aniversariante/aniversariante.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AulasComponent } from './includes/aulas/aulas.component';
import { CertificadoComponent } from './paginas/certificado/certificado.component';
import { FaleConoscoComponent } from './paginas/fale-conosco/fale-conosco.component';
import { TesteWebflowComponent } from './paginas/teste-webflow/teste-webflow.component';
import { ComponentsModule } from './components/components.module';
import { CuidaComponent } from './paginas/cuida/cuida.component';
import { VideoComponent } from './includes/video/video.component';
import { PromotoresComponent } from './paginas/promotores/promotores.component';
import { CadastroCpfComponent } from './includes/cadastro_cpf/cadastro_cpf.component';
import { IndiqueComponent } from './paginas/indique/indique.component';
import { PromotoresmodalComponent } from './includes/promotoresmodal/promotoresmodal.component';
import { RankingPromotoresComponent } from './paginas/ranking-promotores/ranking-promotores.component';
import { TeleporthComponent } from './paginas/teleporth/teleporth.component';
import { TreinamentosComponent } from './paginas/treinamentos/treinamentos.component';
import { ModalGanhouComponent } from './includes/modal-ganhou/modal-ganhou.component';
import { RegulamentoIndiqueComponent } from './includes/regulamento-indique/regulamento-indique.component';
import { VitrineComponent } from './paginas/vitrine/vitrine.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ProdutoModalComponent } from './includes/produto-modal/produto-modal.component';
import { PlacarComponent } from './includes/placar/placar.component';
import { TokenInterceptor } from './token.interceptor';
import { SeguindoComponent } from './paginas/seguindo/seguindo.component';

@NgModule({
    declarations: [
        AppComponent,
        PerfilComponent,
        HeaderComponent,
        IndexComponent,
        CadastroComponent,
        LoginComponent,
        PopupVideoComponent,
        FooterComponent,
        ExtratoComponent,
        PrimeiroAcessoComponent,
        PerfilUsuarioComponent,
        ConfirmacaoComponent,
        RegulamentomodalComponent,
        VitrineComponent,
        RegulamentoIndiqueComponent,
        DescadastrarComponent,
        FaqComponent,
        ContagemRegressivaComponent,
        DescontosComponent,
        RankingComponent,
        LoaderComponent,
        LandingComponent,
        LandingMobileComponent,
        HomeSocialComponent,
        ComentariosComponent,
        MenuInferiorComponent,
        GruposComponent,
        GrupoHeaderComponent,
        HeaderUserComponent,
        HeaderUserPerfilComponent,
        EscolaComponent,
        EnqueteComponent,
        AniversariantesComponent,
        AniversarianteComponent,
        DriveComponent,
        SidebarComponent,
        ExamplePdfViewerComponent,
        AulasComponent,
        CertificadoComponent,
        FaleConoscoComponent,
        TesteWebflowComponent,
        CuidaComponent,
        VideoComponent,
        PromotoresComponent,
        CadastroCpfComponent,
        IndiqueComponent,
        PromotoresmodalComponent,
        RankingPromotoresComponent,
        TeleporthComponent,
        TreinamentosComponent,
        ModalGanhouComponent,
        ProdutoModalComponent,
        PlacarComponent,
        SeguindoComponent

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        NgxUiLoaderModule,
        FontAwesomeModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        NgxUiLoaderModule,
        CountdownModule,
        FontAwesomeModule,
        AutocompleteLibModule,
        NgxExtendedPdfViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true, //environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        NgbCarouselModule,
        ComponentsModule,
        BrowserAnimationsModule,
        MatDialogModule

    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR'},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
