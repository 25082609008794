import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {

  user_id;
  pontos;
  perfil;
  user_name = localStorage.getItem('userName');
  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 

  progress: number = 30;

  posicaoUsuario: number = 0;


  ranking:any = [];
  rankingUsuario:any = [];

  is_desktop = false;

  data = new Date();
  regulamento = false;
  
  constructor( private http:HttpClient, private router:Router, private location:Location, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    this.pontos = localStorage.getItem('pontos');
    
    
    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    
    if(!this.user_id){
     this.router.navigate(['/login']);
    }
    
    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }


    this.obter_ranking();
  }



  obter_ranking() {
    let url = 'participantes_iclub/obter_ranking/1/10/'+this.user_id;
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.posicaoUsuario = data.posicao_usuario;
      this.ranking = data.ranking;
    });
  }


  
  

  goback(){
    this.location.back();
  }

}
