import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {

  public regulamento = false;

  constructor(private router:Router) { }

  ngOnInit() {

    if ($(window).width() < 780){
      this.router.navigate(['/landing-mobile']);
    }

    this.zoomOut();

    $(window).on('resize', () => {
      if ($(window).width() < 780){
        this.router.navigate(['/landing-mobile']);
      }

      this.zoomOut();

    }); 
  }

  zoomOut(){
    var hasHorizontalScroll = 1920 > window.innerWidth;

    console.log(hasHorizontalScroll);

      if (hasHorizontalScroll) {
        console.log('entrou');
        //document.getElementById('Lp_inicial').style.zoom = 'scale(0.65)';// Ajuste o valor de zoom conforme necessário
      }
  }

}
