import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss'],
})
export class FaleConoscoComponent implements OnInit {

  constructor( private router:Router, private route: ActivatedRoute, private http: HttpClient ) { }

  public chamados = [];

  cad_chamado = false;
  chamadoAtual:any = [];
  public user_id;
  public user_name;
  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 
  
  mensagem;
  assunto='';
  anexos;
  fixeiro;

  loading_top = false;
  loading_bottom = false;
  is_desktop = false;
  regulamento = false;

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_chamados();
  }

  obter_chamados(){
    this.loading_top = true;
    this.http.get<any>(environment.API_URL.concat('/fale_conosco_iclub/index/'+this.user_id)).subscribe( data => { 

      if(data.status == 1){
        this.chamados = data.chamados;
        console.log(this.chamados);
      }

      this.loading_top = false;
    });
  }

  abrir_chamado(chamado){
    this.chamadoAtual = chamado;
    this.cad_chamado = false;
    this.mensagem = '';
  }

  novo_chamado(){
    this.mensagem = '';
    this.cad_chamado = !this.cad_chamado;
  }

  criar_chamado() {

    const formData = new FormData();

    if (this.anexos) {
      formData.append('anexos', this.anexos);
    }

    if(!this.assunto){
      alert("Selecione o assunto");
      return;
    }

    if(!this.anexos && !this.mensagem){
      alert("Preencha os campos corretamente");
      return;
    }

    formData.append('assunto', this.assunto);
    formData.append('mensagem', this.mensagem);
    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));

    this.loading_bottom = true;

    this.http.post<any>(environment.API_URL.concat('fale_conosco_iclub/novo'), formData)
      .subscribe(data => {
        this.loading_bottom = false;

        if(data.status == 0){
          alert("Erro: " + data.error);
        }
        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']); 
        }
        if(data.status == 1){
          this.chamadoAtual = data.chamado;
          this.cad_chamado = false;
          this.mensagem = '';
          this.obter_chamados();
        }
    
      }, error => {
        console.error('Erro ao enviar os dados:', error);
        console.log(error);
        // Trate o erro de acordo com sua necessidade
      });

  }
  
  nova_interacao(){
    const formData = new FormData();

    if (this.anexos) {
      formData.append('anexos', this.anexos);
    }

    if(!this.mensagem || this.mensagem.length < 5){
      alert("Escreva algo para enviar");
      return;
    }

    formData.append('id_chamado', this.chamadoAtual.id);
    formData.append('mensagem', this.mensagem);
    formData.append('id_participante', this.user_id);
    formData.append('token', localStorage.getItem('token'));

    this.loading_bottom = true;

    this.http.post<any>(environment.API_URL.concat('fale_conosco_iclub/nova_interacao'), formData)
      .subscribe(data => {
        this.loading_bottom = false;

        if(data.status == 0){
          alert("Erro: " + data.error);
        }
        if(data.status == 7){
          alert("Sua conexão expirou, faça login novamente para continuar");
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          this.router.navigate(['/login']);
        }
        if(data.status == 1){
          this.chamadoAtual = data.chamado;
        }
    
      }, error => {
        console.error('Erro ao enviar os dados:', error);
        console.log(error);
        // Trate o erro de acordo com sua necessidade
      });

  }


  onFileSelected(event: any) {
    const arquivoSelecionado = event.target.files[0];
    this.anexos = arquivoSelecionado;
  }

}
