import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  @ViewChild('slides') slides: IonSlides;


  constructor(private http:HttpClient, private elementRef:ElementRef, private router:Router) { }

  perfil = parseInt(localStorage.getItem('perfil'));
  user_id;
  user_name = localStorage.getItem('userName');
  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 
  grupos = [];
  pontos = 0;

  progress = {
    certificados : 0,
    porcentagem: 0
  }

  paginas = [
    {title: 'Descontos exclusivos', subtitle: 'Grandes marcas parceiras para você economizar!', img: '/assets/personagem-iclub.png', link: 'descontos'},
    {title: 'Sorteios', subtitle: 'Sorteios de prêmios incríveis!', img: 'assets/img/icone_sorteios.png', link: 'sorteios'},
  ]

  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {   // Configurações da paginação (dots)
      el: '.swiper-pagination',   // Elemento HTML que irá conter os dots
    },
  };

  banners: any[];

  @Input() pagina = 'home';
  @Input() aniversario = false;
  @Input() banner: any;
  @Output() regulamento = new EventEmitter();


  ngOnInit() {

    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    console.log(localStorage.getItem('aniversariante'));
    if(localStorage.getItem('aniversariante')){
      this.aniversario = true;
    }

    this.user_id = localStorage.getItem('userId');

    this.obter_progresso();
    this.obter_grupos();

    let width = window.innerWidth;
    
    if( width >= 780 ){
      this.obter_dados();
      setTimeout(()=>{
        this.set_scroll();
      }, 2000);
  
      setInterval(()=>{
        this.set_scroll();
        this.obter_dados();
      }, 7000)
    }

    this.obter_banners_slide();
  }

  obter_progresso(){
    let formData = new FormData();

    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('participantes_iclub/obter_progresso/'+this.user_id), formData).subscribe(
      data => {
        console.log(data);
        if(data.status == 1){
          this.progress = data.progresso;
        }
      }
    )
  }

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
    });
  }

  obter_grupos(){
    let formData = new FormData();

    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('sociais_iclub/obter_grupos'), formData).subscribe(
      data => {
        
        if(data.status == 1){
          this.grupos = data.grupos;
          this.set_scroll();
        }
      }
    )
  }

  obter_banners_slide() {
    this.http.get(environment.API_URL.concat('acoes_iclub/obter_banners_slide')).subscribe((data: any) => {
      if (data.status === 1) {
        this.banners = data.banners;
        console.log(this.banners); 
      } else {
        console.log(data);
      }
    });
  }
  
  abrir_regulamento(){
    this.regulamento.emit({abrir: true});
  }

  set_scroll() {
    const element = document.getElementsByClassName('bg-gray')[0]; // Substitua 'seu-elemento' pelo ID do seu elemento

    element.addEventListener('scroll', () => {
      const scrollTop = element.scrollTop; // Posição da rolagem vertical do elemento
      const targetPosition = 1900; // Posição desejada (1600 pixels no exemplo)
    
      if (scrollTop > targetPosition) {
        this.fixElement();
      } else {
        this.unfixElement();
      }
    });
    
  }
  
  fixElement() {
    const fixedElement = document.getElementById('sidebar');
    fixedElement.classList.add('fixed');
    //console.log('entrou');
  }
  
  unfixElement() {
    const fixedElement = document.getElementById('sidebar');
    fixedElement.classList.remove('fixed');
    //console.log('saiu');
  }
  
}
