import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teste-webflow',
  templateUrl: './teste-webflow.component.html',
  styleUrls: ['./teste-webflow.component.scss'],
})
export class TesteWebflowComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    
  
  }


}
