import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { User } from '../../classes/user';
import { ActivatedRoute, Router } from '@angular/router';

import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmacao',
  templateUrl: './confirmacao.component.html',
  styleUrls: ['./confirmacao.component.scss'],
})
export class ConfirmacaoComponent implements OnInit {

  public id_confirmacao;

  public invalid: boolean = false;
  public msg_invalid: string = null;
  public participante: User;
  codigo='';
  codigo1=''; codigo2=''; codigo3=''; codigo4='';
  ultimos_numeros;
  timeout:boolean = false;
  contador = 40;

  constructor(private rota: ActivatedRoute, private loader: NgxUiLoaderService, private http: HttpClient, private router:Router) { }

  ngOnInit() {
    this.participante = new User();
    this.id_confirmacao = localStorage.getItem('id_confirmacao');
    this.obter_dados();

  
    var timer = setInterval(()=>{
      this.contador --;
    }, 1000);

    setTimeout(() => {
      this.timeout = true;
      this.contador = 0;
      clearInterval(timer);
    }, 40000);


  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('cadastro/obter_id/'+this.id_confirmacao)).subscribe( data => {

      if(data.status == 1){
        this.participante = data.user;
        this.ultimos_numeros = data.user.celular.substr(7, 4);
        this.gerar_token();
      }
      else{
        console.log(data);
      }

    });

  }

  next_input(event){
    let element = event.target.value;
    let next = event.target.nextElementSibling;
    let prev = event.target.previousElementSibling;

    console.log(event.target);
    if(element != ''){
      if(next)  next.select();
    }
    else{
      prev.select();
    }
    console.log(element);

  }

  gerar_token() {
      this.http.post<any>(environment.API_URL.concat('cadastro/solicitar_token'), {
          documento: this.participante.cpf
      }).subscribe(data => {

          if (data.status == 0) {
              //alert(data.erro);
              return;
          }
          alert('Novo código enviado');
          this.loader.stop();

      }, error => {
          console.log(error)
          this.loader.stop();
      });
  }
  
  confirmar_cadastro() {

    let token = '';
    //this.participante.token_cadastro = token + this.codigo1 + this.codigo2 + this.codigo3 + this.codigo4;
    this.participante.token_cadastro = this.codigo;

    console.log(this.participante.token_cadastro);
    if (!this.participante.token_cadastro || this.participante.token_cadastro == '') {
        this.msg_invalid = "Informe o código enviado por SMS";
        this.invalid = true;
        return;
    }

    this.http.post<any>(environment.API_URL.concat('cadastro/confirmar_cadastro'), {
        token: this.participante.token_cadastro,
        documento: this.participante.cpf
    }).subscribe(data => {

      if (data.status == 0) {
          alert(data.erro);
          return;
      }

      //Ação para login
      localStorage.setItem('isLogged', 'true');
      localStorage.setItem('perfil', data.perfil);
      
      var nome_view;
      let nome = data.nome.split(' ');
      if(nome.length > 2){
        nome_view = nome[0]+' '+nome[1]; 
      }
      else{
        nome_view = data.nome; 
      }

      localStorage.setItem("userName", nome_view);
      localStorage.setItem('userId', data.id);
      this.router.navigate(['/primeiro-acesso']).then(()=>{
        window.location.reload();
      });

    }, error => {
        console.log(error)
    });
  }

}
