import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aniversariante',
  templateUrl: './aniversariante.component.html',
  styleUrls: ['./aniversariante.component.scss'],
})


export class AniversarianteComponent implements OnInit {

  post: { comments } = { comments: [] };
  mensagens = [];

  user_id;
  user_name;
  is_desktop = false;
  foto_src: string = "/assets/Imagem1.png"; 
  regulamento;

  constructor(private router:Router ,private route:ActivatedRoute, private http:HttpClient) { }

  ngOnInit() {

    this.initializeData();

    this.user_id = localStorage.getItem('userId');
    const rota: string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    if (localStorage.getItem('foto_src')) {
      this.foto_src = localStorage.getItem('foto_src');
    }

    this.obter_mensagens();
  }

  toggleDenunciation(content: any): void {
    content.showDenunciations = !content.showDenunciations;
  }

  
  private initializeData(): void {
    const comment1 = {
      participante: "Participante 1",
      text: "Parabenms pelo aniversário! Que Deus te abençoe! seja feliz! te amo! te adoro! te quero bem! tenha um ótimo dia! tenha sucesso na sua nova caminhada",
    };

    const comment2 = {
      participante: "Participante 2",
      text: "Texto do comentário 2",
    };

    this.post.comments = [comment1, comment2];
  }

  obter_mensagens(){
    this.http.get<any>(environment.API_URL.concat('acoes_iclub/obter_mensagens/'+this.user_id)).subscribe( data => {

      if(data.status == 1){
        this.mensagens = data.mensagens;
      }

    })
  }
}
