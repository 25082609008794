import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ignoreElements } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-descontos',
  templateUrl: './descontos.component.html',
  styleUrls: ['./descontos.component.scss'],
})

export class DescontosComponent implements OnInit {

  user_id;
  user_name;
  perfil = parseInt(localStorage.getItem("perfil"));

  pagina = 1;
  beneficios:any = [];

  @ViewChild('modalAcao', { static: false }) private modalAcao: TemplateRef<any>;
  
  regulamento = false;
  is_desktop = false;

  ranking;
  posicao;


  constructor( renderer: Renderer2, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpClient, private loader: NgxUiLoaderService) {
  }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');


    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }
    
    this.obter_beneficios();

    if(this.perfil !== 1){
      this.obter_ranking();
    }
  }


  obter_beneficios() {
    this.http.get(environment.API_URL.concat('beneficios/obter_beneficios')).subscribe((data: any) => {
      const beneficios = data;
      
      const meio = Math.floor(beneficios.length / 2);

      this.beneficios['mostrar'] = data.slice(0, meio);
      this.beneficios['pag1'] = data.slice(0, meio);
      this.beneficios['pag2'] = data.slice(meio);


    })
  }

  set_pagina(p){
    this.pagina = p;
    this.beneficios['mostrar'] = this.beneficios['pag'+p];
  }

  registrar_acesso(beneficio) {
        console.log(beneficio);

        // (<any>window).dataLayer.push({
        //     'event': 'clubeMDOEvents',
        //     'category': beneficio.nome,
        //     'action': 'clique'
        // });

        this.http.post(environment.API_URL.concat('beneficios/registrar_acesso'),
            { 'beneficio': beneficio }).subscribe((data: any) => {
                console.log('registrado');
            });
  }


  obter_ranking() {
    let url = 'participantes_iclub/obter_ranking/'+this.perfil+'/1000';
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking = data.slice(0, 5);
      let indice = data.findIndex(item => item.id === this.user_id);
      this.posicao = indice+1;
    });
  }
  

}


