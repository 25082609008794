import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enquete',
  templateUrl: './enquete.component.html',
  styleUrls: ['./enquete.component.scss'],
})
export class EnqueteComponent implements OnInit {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private router:Router, private route:ActivatedRoute) { }

  user_id:any;
  token:any;
  quiz:any = [];
  perguntas:any;
  safeURL:any;
  respostas:any = [];
  acertou:any;
  resposta_correta:any;

  etapa = 0;
  is_desktop = false;

  regulamento = false;

  dynamicImageUrl : string;
  public modal_ganhou: boolean = false;

  ngOnInit() {

    this.user_id = localStorage.getItem("userId");
    this.token = localStorage.getItem("token");
  
    if (!this.user_id || !this.token) {
      this.router.navigate(['/login']);
    }

    this.buscar_quiz();
  
    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_dados();
  }

  set_resposta(ordem, index){
    this.respostas[index] = ordem;
  }

  buscar_quiz() {
  
    this.http.get<any>(environment.API_URL.concat('quiz_iclub/index/' + this.user_id)).subscribe(data => {
      console.log(data);
      this.quiz = data.quiz;
      this.perguntas = data.perguntas;
      this.perguntas.forEach((p) => { this.respostas.push(0) });
      if (data.quiz.url_material) {
        this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.quiz.url_material);
      }

    });
  }
  

  responder_quiz() {

    this.http.post<any>(environment.API_URL.concat('quiz_iclub/responder_quiz/'), {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas
    }).subscribe(data => {

      this.acertou = data.acertou;
      this.resposta_correta = data.resposta_correta;

      if (data.acertou == 'S') {
        // let efeito_sonoro = new Audio("assets/audio/positivo.wav");
        // efeito_sonoro.play();
        // this.modal_ganhou = true;
        // this.dynamicImageUrl = 'assets/win_quiz.png';
        this.pontos_ganhos = data.pontos;
        this.animacao_saldo();

        const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
        const quizIndex = quizesNaoVisualizados.findIndex(quiz => quiz.id === this.quiz.id);
        if (quizIndex !== -1) {
          quizesNaoVisualizados[quizIndex].respondido = true;
          localStorage.setItem('quizes_nao_visualizados', JSON.stringify(quizesNaoVisualizados));
        }
        
      } else{
        let efeito_sonoro = new Audio("assets/audio/resposta_errada.wav");
        efeito_sonoro.play();
      }       

      this.etapa = 2;

    });
  }

  responder_enquete(){
    this.http.post<any>(environment.API_URL.concat('quiz_iclub/responder_enquete'), {
      id_usuario: this.user_id,
      id_quiz: this.quiz.id,
      respostas: this.respostas
    }).subscribe(data => {
      this.acertou = data.acertou;
      this.etapa = 2;

      /* let efeito_sonoro = new Audio("assets/audio/acertou.mp3");
      efeito_sonoro.play(); */

    });
  }

  pontos;
  moedas;
  pontos_ganhos;
  moedas_ganhos;
  loaded_data;

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });
  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { this.modal_ganhou = false }, 4000);
  }

  atualizar_saldos($event) {
    setTimeout(() => {
      this.obter_dados();
      this.modal_ganhou = false;
    }, 3000);
  }
  
}
