import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-ranking-promotores',
  templateUrl: './ranking-promotores.component.html',
  styleUrls: ['./ranking-promotores.component.scss'],
})
export class RankingPromotoresComponent implements OnInit {

  user_id;
  pontos;
  perfil;
  posicao;
  progress: number = 30;

  ranking:any = [];
  ranking_promotores:any = [];
  ranking_consultores:any = [];
  ranking_ativo;

  modal = false;

  data = new Date();
  regulamento = false;
  
  constructor( private http:HttpClient, private router:Router, private location:Location, private route: ActivatedRoute ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('userId');
    
    if(!this.user_id){
     this.router.navigate(['/login']);
    }
    
    let rota:string = this.route.snapshot.url.toString();

    this.perfil = parseInt(localStorage.getItem('perfil'));

    this.obter_ranking();
  }


  obter_ranking() {
    let url = 'participantes_iclub/obter_ranking/2/10';
    if(this.perfil == 2 ) {
      url = url+"/"+this.user_id; 
    }
  
    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking_promotores = data.ranking;
      this.set_ranking(this.perfil);

      if(this.perfil == 2){
        this.posicao = data.posicao_usuario;
      }
    });

    url = 'participantes_iclub/obter_ranking/3/10';

    if(this.perfil == 3 ) {
      url = url+"/"+this.user_id; 
    }

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      this.ranking_consultores = data;
      this.set_ranking(this.perfil);

      if(this.perfil == 3){
        this.posicao = data.posicao_usuario;
      }
    });

  }
  

  goback(){
    this.location.back();
  }


  set_ranking(perfil){

    console.log(perfil, this.ranking_promotores, this.ranking_consultores);
    this.ranking_ativo = perfil;

    if(perfil == 2){
      this.ranking = this.ranking_promotores;
    }

    if(perfil == 3){
      this.ranking = this.ranking_consultores;
    }

  }

}
