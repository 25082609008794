import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

interface Produto {
  id: number;
  nome: string;
  descricao: string;
  tamanhos: string;
  cores: string;
  preco: number;
  skus: [SKU];
}
interface SKU {
  id: number;
  id_produto: number;
  tamanho: string;
  cor: string;
  imagem: string;
  estoque: string;
  preco: number;
}
@Component({
  selector: 'app-produto-modal',
  templateUrl: './produto-modal.component.html',
  styleUrls: ['./produto-modal.component.scss'],
})

export class ProdutoModalComponent implements OnInit {
  
  tamanhosDisponiveis: string[] = [];
  coresDisponiveis: string[] = [];
  produtosTamanhos;

  tamanhoSelecionado: string = '';
  corSelecionada: string = '';
  skuSelecionado: SKU;
  endereco;

  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  

  passoAtual = 1;

  user_id: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ProdutoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public produto: Produto
  ) {}

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.obter_endereco();
    this.processarCores();
    this.processarTamanhos();
  
    if (this.coresDisponiveis.length > 0) {
      this.corSelecionada = this.coresDisponiveis[0];
    }
  }

  private processarTamanhos(): void {
    this.tamanhosDisponiveis = this.produto.tamanhos.split('|');
  }
  
  private processarCores(): void {
    this.coresDisponiveis = this.produto.cores.split('|');
  }
  
  obterSKU() {
    const filtro = new FormData();
    filtro.append('id_produto', this.produto.id.toString());
  
    if (this.tamanhoSelecionado) {
      filtro.append('tamanho', this.tamanhoSelecionado);
    }
    if (this.corSelecionada) {
      filtro.append('cor', this.corSelecionada);
    }
  
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(environment.API_URL.concat('vitrine_iclub/obter_skus'), filtro, { headers: headers }).subscribe(
      (data: any) => {
        if (data.status === 1) {
          if (data.skus.length === 1) {
            this.skuSelecionado = data.skus[0];
          }
  
          if (!this.corSelecionada) {
            this.coresDisponiveis = data.cores_disponiveis;
          }

          if(!this.tamanhoSelecionado){
            this.tamanhosDisponiveis = data.tamanhos_disponiveis;
          }

        } else {

          console.error("Erro ao obter os dados do produto:", data.message);
          alert("Ocorreu um erro ao obter os dados do produto. Por favor, tente novamente mais tarde.");
        }
      },
      error => {
        console.error("Erro na solicitação HTTP:", error);
        alert("Ocorreu um erro na solicitação. Por favor, tente novamente mais tarde.");
      }
    );
  }

  resgatar() { 

    if(!this.corSelecionada){
      alert("Selecione uma Cor");
      return;
    }

    if(!this.tamanhoSelecionado){
      alert("Selecione um tamanho");
      return;
    }

    this.http.post<any>(environment.API_URL.concat('vitrine_resgate_iclub/inserir_resgate'), {
      id_produto: this.produto.id,
      id_sku: this.skuSelecionado.id,
      id_participante: this.user_id,
      status: 0,
      tamanho: this.tamanhoSelecionado,
      cor: this.corSelecionada,
      nome: this.produto.nome,
      url_img: this.skuSelecionado.imagem
    }).subscribe(data => {

      if(data.status == 1){
        alert('Resgate realizado com sucesso, acompanhe seu pedido na aba `Meus Pedidos`');
      }
      else{
        alert("Erro: "+data.error);
      }
      console.log(data);
      this.dialogRef.close();
    }
    );
  }

  obter_endereco(){
    this.http.get(environment.API_URL.concat('participantes_iclub/obter_endereco/'+this.user_id)).subscribe( (data:any) => {
        if(data.status == 1){
            console.log(data);
            this.endereco = data.endereco;
        }
        else{
            console.log(data);
        }
    })
  }

  confirmarEndereco() {
    this.passoAtual = 2;
  }

  editarEndereco() {
    this.dialogRef.close();
    this.router.navigate(['/perfil-usuario']);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
