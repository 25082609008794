import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss'],
})
export class CertificadoComponent implements OnInit {

  constructor(private route:ActivatedRoute, private router:Router, private http:HttpClient) { }

  public id_participante;
  public id_curso;
  public id_modulo;

  public infos_cert = { 
    cpf : 0,
    nome : '',
    data : new Date(), 
    curso : '',
    aulas: ''
  }
  public cpf;
  public data;
  public curso;

  ngOnInit() {
    const paramMap = this.route.snapshot.paramMap;
    const id = paramMap.get('id_participante');
    const id_curso = paramMap.get('id_curso');
    const id_modulo = paramMap.get('id_modulo');

    if(!id){
      this.router.navigate(['/escola']);
      return;
    }

    if( window.innerWidth < 760 ){
      alert('Acesse o Iclub pelo computador para baixar seu certificado');
      this.router.navigate(['/escola']);
      return;
    }

    this.id_participante = id;
    this.id_curso = id_curso ?? null;
    this.id_modulo = id_modulo ?? null;
    this.obter_dados();
  }

  captureScreen() {
    const elementToCapture = document.getElementById('certificado');

    html2canvas(elementToCapture).then(canvas => {

      const imageData = canvas.toDataURL('image/png');
  
      // Criando um link de download
      const downloadLink = document.createElement('a');
      downloadLink.href = imageData;
      downloadLink.download = `${this.infos_cert.nome}_${this.infos_cert.curso}.png`
  
      // Adicionando o link de download ao corpo da página
      document.body.appendChild(downloadLink);

      downloadLink.click();
  
      document.body.removeChild(downloadLink);

      setTimeout(() => {
        this.router.navigate(['/escola']);
      }, 30000);
    });
  }

  obter_dados(){

    this.http.get<any>(environment.API_URL.concat('cursos_iclub/obter_certificado_modulo/'+this.id_participante+'/'+this.id_modulo)).subscribe( data =>{

      console.log(data);
      if(data.status == 1){

        this.infos_cert.nome =    data.participante.nome;
        this.infos_cert.cpf =     data.participante.cpf;
        this.infos_cert.data =    new Date();
        this.infos_cert.curso =   data.modulo.nome;
        this.infos_cert.aulas =   data.aulas;

        setTimeout(()=>{
          this.captureScreen();
        }, 5000);

      }
      else{
        alert("Houve um erro ao processar os dados, favor entre em contato com o administrador do sistema");
      }
    })

  }

  obterDataAtual() {
    var data = new Date();
    var dia = this.padZero(data.getDate());
    var mes = this.padZero(data.getMonth() + 1); // Os meses são indexados a partir de zero
    var ano = data.getFullYear();
  
    return dia + '/' + mes + '/' + ano;
  }

  padZero(numero) {
    return numero < 10 ? '0' + numero : numero;
  }

  voltar(){
    this.router.navigate(['/escola-desktop']);
  }

}
