import { Component } from '@angular/core'

@Component({
  selector: 'social-bar',
  templateUrl: 'social-bar.component.html',
  styleUrls: ['social-bar.component.css'],
})
export class SocialBar {
  constructor() {}
}
