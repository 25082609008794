import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-escola',
  templateUrl: './escola.component.html',
  styleUrls: ['./escola.component.scss'],
})

export class EscolaComponent implements OnInit {
  cursos: any = [];
  usuario: any = {}; // Dados do usuário logado
  user_id;
  user_name;
  perfil = parseInt(localStorage.getItem('perfil'));
  cursos_concluidos = [];
  modulos_concluidos = [];

  cursoSelecionado:any = [];
  show_certificados: boolean = false;
  
  is_desktop = false;
  regulamento = false;
  loading_top = false;

  public modal_ganhou: boolean = false;
  dynamicImageUrl: string;

  constructor(private router:Router, private http: HttpClient, private route:ActivatedRoute) { }

  ngOnInit() {
    
    this.cursos = [ ];

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    if( this.perfil !== 1 ){
      this.router.navigate(['/promotores']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_cursos();
    this.obter_dados();
  }

  selecionarCurso(curso) {
    //console.log(curso);
    this.cursoSelecionado = curso;
  } 


  calcularProgressoCurso() {
    this.cursos.forEach((curso) => {
      const aulasFeitas = this.calcularAulasFeitas(curso);
      const totalAulas = curso.qtd_aulas;
      const porcentagem = (aulasFeitas.length / totalAulas) * 100;
      
      curso.progresso = Math.round(porcentagem);
      this.atualizarCursosConcluidos(curso);
    });
  }
  
  calcularAulasFeitas(curso) {
    return curso.modulos.flatMap(modulo =>
      modulo.aulas.flatMap(aula =>
        aula.participantes.filter(participante => participante.id_participante === this.user_id)
      )
    );
  }
  
  atualizarCursosConcluidos(curso) {
    if (curso.progresso === 100 && !this.cursos_concluidos.some(c => c.id === curso.id)) {
      curso.modulo_final = curso.modulos[curso.modulos.length - 1].nome;
      this.cursos_concluidos.push(curso);
    }
  }
    
  calcularProgressoModulo() {
    this.cursos.forEach((curso) => {
      curso.modulos.forEach(modulo => {
        const aulasFeitas = this.calcularAulasFeitasModulo(modulo);
        const totalAulas = modulo.qtd_aulas;
        const porcentagem = (aulasFeitas.length / totalAulas) * 100;

        modulo.progresso = Math.round(porcentagem);
        this.atualizarModulosConcluidos(modulo, curso);
      });
    });
  }

  calcularAulasFeitasModulo(modulo) {
    return modulo.aulas.flatMap(aula =>
      aula.participantes.filter(participante => participante.id_participante === this.user_id)
    );
  }

  atualizarModulosConcluidos(modulo, curso) {
    if (modulo.progresso === 100 && !this.modulos_concluidos.some(m => m.id === modulo.id)) {
      modulo.modulo_final = curso.modulos[curso.modulos.length - 1].nome;
      this.modulos_concluidos.push(modulo);
    }
  }

  exibirModalGanhou(){
    this.modal_ganhou = true;
    this.dynamicImageUrl = 'assets/win_certificado.png';
    
    let efeito_sonoro = new Audio("assets/audio/positivo.wav");
    efeito_sonoro.play(); 
  }

  obter_cursos(){
    this.loading_top = true;
    this.http.get<any>(environment.API_URL.concat('/cursos_iclub/')).subscribe( data => { 

      if(data.status == 1){
        this.cursos = data.cursos;
        this.calcularProgressoCurso();
        this.calcularProgressoModulo();
      }
      this.loading_top = false;
    });
  }

  finalizar_aula($event){

    let curso = this.cursos.find((c)=> { return c.id== $event.modulo.id_curso });
    let modulo = curso.modulos.find((m) => { return m.id== $event.modulo.id });
    let aula = modulo.aulas.find(a => { return a.id == $event.aula.id });
    
    const index_curso = this.cursos.indexOf(curso);
    const index_modulo = curso.modulos.indexOf(modulo);
    const index_aula = modulo.aulas.indexOf(aula);

    this.cursos[index_curso].modulos[index_modulo].aulas[index_aula].participantes.push(
      {
        id_participante: this.user_id, 
        id_aula: aula.id, 
        participante: this.user_name
      }
    );
    
    this.cursoSelecionado = this.cursos[index_curso];

    if($event.pontos){
      this.pontos_ganhos = $event.pontos;
      this.animacao_saldo();
    }
    
  }


  certificados(){
    this.show_certificados = true;
    this.cursoSelecionado = [];
    //console.log(this.cursos_concluidos);
  }

  baixar_certificado(modulo){
    this.router.navigate(['/certificado', {'id_modulo': modulo.id, 'id_participante': this.user_id}]);
  }


  pontos;
  moedas;
  pontos_ganhos;
  moedas_ganhos;
  loaded_data;

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });
  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { this.modal_ganhou = false }, 4000);
  }

  atualizar_saldos($event) {
    setTimeout(() => {
      this.obter_dados();
      this.modal_ganhou = false;
    }, 3000);
  }

}


