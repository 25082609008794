import { Component, Injectable, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';


@Component({
  selector: 'app-modal-ganhou',
  templateUrl: './modal-ganhou.component.html',
  styleUrls: ['./modal-ganhou.component.scss'],
})
export class ModalGanhouComponent implements OnInit {

  constructor(private modalController: ModalController, private route: ActivatedRoute, private router:Router) { }
 
  @Input() isOpen = true;
  @Input() imagemUrl: string;
  @Input() pontos_ganhos;

  ngOnInit() {
    this.iniciarAnimacao();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen) {
      this.isOpen = changes.isOpen.currentValue;
      if (this.isOpen) {
        this.iniciarAnimacao();
      }
    }
  }

  iniciarAnimacao() {
    setTimeout(() => {
      this.fecharModal();
      this.modalController.dismiss();
    }, 9000);

  }

  fecharAoClicar() {
    this.fecharModal();
    this.modalController.dismiss();
  }

  fecharModal() {
    this.isOpen = false;
  }

}
