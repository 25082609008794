import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-aniversariantes',
  templateUrl: './aniversariantes.component.html',
  styleUrls: ['./aniversariantes.component.scss'],
})
export class AniversariantesComponent implements OnInit {

  public user_id;
  public user_name;
  public aniversariantesCompletos: any = [];
  public aniversariantesFiltrados: any = [];
  foto_src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  loading_top = false;
  loading_bottom = false;
  is_desktop = false;

  filtroTexto = '';
  filtroDia = false;
  filtroSemana = false;
  filtroTodos = true;

  data = new Date();
  regulamento = false;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');

    if (!this.user_id) {
      this.router.navigate(['/login']);
    }

    if (localStorage.getItem('foto_src')) {
      this.foto_src = localStorage.getItem('foto_src');
    }

    let rota: string = this.route.snapshot.url.toString();

    if (rota.includes('desktop')) {
      this.is_desktop = true;
    }

    this.obterAniversariantes();
  }

  filtrarAniversariantes() {
    const dataAtual = new Date();
    const diaAtual = dataAtual.getDate();
    const mesAtual = dataAtual.getMonth();
    const semanaAtual = this.getSemanaAtual(dataAtual);
    if (this.filtroDia) {
      this.aniversariantesFiltrados = this.aniversariantesCompletos.filter(aniversariante => {
        const dataAniversario = new Date(aniversariante.aniversario);
        const diaAniversario = dataAniversario.getDate() + 1;
        const mesAniversario = dataAniversario.getMonth();
        console.log(diaAtual, diaAniversario);

        return diaAniversario === diaAtual && mesAniversario === mesAtual;
      });
    } else if (this.filtroSemana) {
      this.aniversariantesFiltrados = this.aniversariantesCompletos.filter(aniversariante => {
        const dataAniversario = new Date(aniversariante.aniversario);
        const semanaAniversario = this.getSemanaDoMes(dataAniversario);
        console.log(semanaAniversario, semanaAtual);
        return semanaAniversario === semanaAtual && dataAniversario.getMonth() === mesAtual;
      });
    } else {
      this.aniversariantesFiltrados = this.aniversariantesCompletos;
    }
  }

  getSemanaAtual(data) {
    const primeiroDiaDoMes = new Date(data.getFullYear(), data.getMonth(), 1);
    const diasPassados = data.getDate() - primeiroDiaDoMes.getDate();
    return Math.floor(diasPassados / 7);
  }

  getSemanaDoMes(data) {
    const primeiroDiaDoMes = new Date(data.getFullYear(), data.getMonth(), 1);
    const diasPassados = data.getDate() - primeiroDiaDoMes.getDate();
    return Math.floor(diasPassados / 7);
  }


  obterAniversariantes() {
    let url = 'acoes_iclub/obter_aniversariantes/'+this.user_id;

    this.http.get<any>(environment.API_URL.concat(url)).subscribe((data) => {
      this.aniversariantesCompletos = data.aniversariantes;
      this.filtrarAniversariantes();
    });
  }

  aplicarFiltroDia() {
    this.filtroTexto = '';
    this.filtroDia = true;
    this.filtroSemana = false;
    this.filtroTodos = false;
    this.filtrarAniversariantes();
  }

  aplicarFiltroSemana() {
    this.filtroTexto = '';
    this.filtroDia = false;
    this.filtroSemana = true;
    this.filtroTodos = false;
    this.filtrarAniversariantes();
  }

  aplicarFiltroTodos() {
    this.filtroTexto = '';
    this.filtroDia = false;
    this.filtroSemana = false;
    this.filtroTodos = true;
    this.filtrarAniversariantes();
  }

  aplicarFiltroTexto(){
    this.aniversariantesFiltrados = this.aniversariantesCompletos.filter( aniversariante => aniversariante.nome.toLowerCase().includes(this.filtroTexto.toLowerCase()) );
  }

  enviar_msg(event: MouseEvent, id_aniversariante: string) {

    const id_participante = this.user_id;
    // Encontre o elemento de entrada de texto associado ao ícone clicado
    const inputElement = (event.target as HTMLElement).closest('.comment').querySelector('input') as HTMLInputElement;
    // Obtenha o texto do campo de entrada
    const mensagem = inputElement.value;

    const aniversariante =  this.aniversariantesFiltrados.find( aniversariante => aniversariante.id === id_aniversariante );
    const index = this.aniversariantesFiltrados.indexOf(aniversariante);
    this.aniversariantesFiltrados[index].mostrarBotao = false;

    if (!mensagem) {
      return;
    }

    if(mensagem.length > 400){
      alert("Mensagem muito grande, envie uma mensagem de até 400 caracteres");
      return;
    }

    // Envie a solicitação HTTP para salvar a mensagem no backend
    this.http.post<any>(environment.API_URL.concat('acoes_iclub/msg_aniversario'), { id_participante, id_aniversariante, mensagem }).subscribe(response => {
      
      if (response.status == 1) {

        this.aniversariantesFiltrados[index].enviado = true;
        //this.aniversariantesFiltrados = this.aniversariantesFiltrados.filter( aniversariante => aniversariante.id !== id_aniversariante );
      }

    });

  }


}
