import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
  
declare const gapi: any;
declare const google: any;

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.scss'],
})
export class DriveComponent implements OnInit {

  pastas = [];
  pastas_filtradas = [];

  filter_box = false;

  filter_opts = [
    'Nome', 'Tamanho', 'Modificado', 'Data criação'
  ];

  filter_order = [
    'Ascendente', 'Descendente'
  ];

  filter = {
    'opt': 'Nome',  
    'order': 'Ascendente'
  };


  arquivos = [];
  arquivos_filtrados = [];

  filtro_pasta;
  filtro_arquivo;
  selecionado = false;


  regulamento = false;
  is_desktop = false;
  loading = false;

  constructor(private renderer:Renderer2, private route:ActivatedRoute, private http:HttpClient) { }

  ngOnInit() {
    this.pastas_filtradas = this.pastas;
    this.arquivos_filtrados = this.arquivos;

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_drive();
    
  }

  obter_drive(){


    this.http.get<any>(environment.API_URL.concat('drive_iclub')).subscribe( data => {

      this.pastas_filtradas = data.grupos;
      this.pastas = data.grupos;

    });

  }

  selecionar(pasta){
    this.selecionado = pasta.nome;
    this.arquivos = pasta.arquivos;
    this.arquivos_filtrados = pasta.arquivos;
  }

  filtrarPastas(){
    this.pastas_filtradas = this.pastas.filter(
      pasta=> pasta.nome.toLocaleLowerCase().includes(this.filtro_pasta.toLowerCase())
    );
  }


  filtrarArquivos(){
    this.arquivos_filtrados = this.arquivos.filter(
      arquivo => arquivo.nome.toLocaleLowerCase().includes(this.filtro_arquivo.toLowerCase())
    );
  }

  recarregar(){
    this.selecionado = false;
    this.filtro_pasta = '';
    this.filtro_arquivo = '';
    this.pastas_filtradas = [];

    setTimeout(()=>{
      this.pastas_filtradas = this.pastas;
    }, 500)
  }

  set_filter(filter){
    this.filter['opt'] = filter;
  }

  set_order(order){
    this.filter['order'] = order;
  }


  downloadArquivo(url, nomeArquivo) {
    const arquivoUrl = url; // URL do arquivo

    // Cria um elemento <a> temporário
    const link = document.createElement("a");
    link.href = arquivoUrl;

    // Extrai o nome do arquivo do URL

    // Define o atributo "download" para indicar o nome do arquivo a ser baixado
    link.setAttribute("download", nomeArquivo);

    // O link não deve ser exibido na página
    link.style.display = "none";

    // Adiciona o elemento <a> à página
    document.body.appendChild(link);

    // Aciona o clique no link para iniciar o download
    link.click();

    // Remove o elemento <a> temporário da página após o download
    document.body.removeChild(link);
}



}
