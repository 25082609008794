import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teleporth',
  templateUrl: './teleporth.component.html',
  styleUrls: ['./teleporth.component.scss'],
})

export class TeleporthComponent implements OnInit {

  pontos;
  perfil;
  progress = 30;
  cpf;

  sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    dots: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {   // Configurações da paginação (dots)
      el: '.swiper-pagination',   // Elemento HTML que irá conter os dots
    },
  };


  ranking: any = [];

  isDesktop = false;

  data = new Date();
  regulamento = false;

  constructor( private http: HttpClient, private router: Router, private location: Location, private route: ActivatedRoute ) { }

  ngOnInit() {

    const rota: string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.isDesktop = true;
    }

    if(localStorage.getItem('userId')){
      this.router.navigate(['/home']);
    }


    //this.obterRanking();
  }

  // obterRanking() {
  //   const url = 'participantes_iclub/obter_ranking';

  //   this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
  //     this.ranking = data;
  //   });
  // }

  goback(){
    this.location.back();
  }

}
