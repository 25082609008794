import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {

  public regulamento;
  public is_logged = false;
  public regulamento_indique;
  public perfil = parseInt(localStorage.getItem('perfil'));
  public device = 'mobile';

  public menu = [ 
    { title: 'Home',                    subtitle: 'Veja a linha do tempo',      url: '/home',      },
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil-usuario',      },
    { title: 'Vitrine de prêmios',      subtitle: 'Resgate seus pontos',        url: '/vitrine'  },
    { title: 'Arquivos',                subtitle: 'Conteudo extra',             url: '/drive'  },
    { title: 'Descontos',               subtitle: 'Grandes marcas parceiras',   url: '/descontos'  },
    //{ title: 'Indique',                 subtitle: 'Indique pintores e rankeie', url: '/indique' },
    { title: 'Iclub Cuida de você',     subtitle: 'Confira vídeos exclusivos ', url: '/cuida'  },
    { title: 'Agenda Treinamentos',     subtitle: 'Próximos eventos',           url: '/treinamentos'  },
    { title: 'Chega Junto',             subtitle: 'Envie sua dúvida',           url: '/fale-conosco'  } 
  ];

  public menu_desktop = [ 
    { title: 'Home',                    subtitle: 'Veja a linha do tempo',      url: '/home-desktop',      },
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil-usuario-desktop',      },
    { title: 'Vitrine de prêmios',      subtitle: 'Resgate seus pontos',        url: '/vitrine-desktop'  },
    { title: 'Grupos',                  subtitle: 'Postagens',                  url: '/grupos-desktop'  },
    { title: 'Escolas',                 subtitle: 'Cursos',                     url: '/escola-desktop'  },
    { title: 'Quiz',                    subtitle: 'Teste seu conhecimento',     url: '/enquete-desktop'  },
    { title: 'Arquivos',                subtitle: 'Conteudo extra',             url: '/drive-desktop'  },
    { title: 'Descontos',               subtitle: 'Grandes marcas parceiras',   url: '/descontos-desktop'  },
    //{ title: 'Indique',                 subtitle: 'Indique pintores e rankeie', url: '/indique-desktop' },
    { title: 'Iclub Cuida de você',     subtitle: 'Confira vídeos exclusivos ', url: '/cuida-desktop'  },
    { title: 'Agenda Treinamentos',     subtitle: 'Próximos eventos',           url: '/treinamentos-desktop'  },
    { title: 'Chega Junto',             subtitle: 'Envie sua dúvida',           url: '/fale-conosco-desktop' } 
  ];

  public menu_promotores = [
    { title: 'Home',                    subtitle: 'Veja a linha do tempo',      url: '/promotores',      },
    //{ title: 'Indique',                 subtitle: 'Indique pintores e rankeie', url: '/indique' },
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil-usuario',      },
    { title: 'Descontos',               subtitle: 'Grandes marcas parceiras',   url: '/descontos'  },
  ];

  public menu_promotores_desktop = [
    { title: 'Home',                    subtitle: 'Veja a linha do tempo',      url: '/promotores-desktop',      },
    //{ title: 'Indique',                 subtitle: 'Indique pintores e rankeie', url: '/indique-desktop' },
    { title: 'Perfil',                  subtitle: 'Seus dados',                 url: '/perfil-usuario-desktop',      },
    { title: 'Descontos',               subtitle: 'Grandes marcas parceiras',   url: '/descontos-desktop'  },
  ];
  private routerSubscription: Subscription;

  
  constructor( private platform: Platform, private router : Router
   // private splashScreen: SplashScreen,
   // private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {

    this.setDevice();

    if(localStorage.getItem("userId")){
      this.is_logged = true;
    }

    $(window).on('resize', () => {
      this.handleChanges();
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleChanges();

        if (event instanceof NavigationEnd) {
          this.trackPageView();
        }

        setTimeout(()=>{
          this.definir_css_desktop();
        }, 1000);
      }
    });

  }

  trackPageView() {
    setTimeout(()=>{
      if (typeof (window as any).mt !== 'undefined') {
        (window as any).mt('send', 'pageview');
      }
    }, 400);
  }

  definir_css_desktop(){

    $(document).ready(function() {
      $('#content').each(function() {

        if ($(this).find('.timeline-desk').length > 0) {
          // Faça algo com o elemento pai que contém o elemento filho
          $(this).addClass('content-with-timeline');
        }
      });
    });

  }

  initializeApp() {
    this.platform.ready().then(() => {
     // this.statusBar.styleDefault();
     // this.splashScreen.hide();
    });
  } 

  abrir_regulamento(){
    this.regulamento = true;
  }

  abrir_regulamento_indique(){
    this.regulamento_indique = true;
  }

  logout(){

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('foto_src');
    localStorage.removeItem('legado');
    localStorage.removeItem('perfil');
    localStorage.removeItem('admin');
    localStorage.removeItem('userName');
    localStorage.removeItem('userName0');
    localStorage.removeItem('userId0');
    localStorage.removeItem('token0');
    localStorage.removeItem('perfil0');
    localStorage.removeItem('foto_src0');
    localStorage.removeItem('isEmbaixador');

    

    setTimeout(()=>{
      this.router.navigate(['/login']).then(()=> { location.reload() });
    }, 500);

  }

  fechar_menu(){
    let menu =  $('ion-menu');
    menu.removeClass('show-menu');
    let backdrop =  $('ion-backdrop');
    backdrop.removeClass('show-backdrop');
  }

  setDevice(): void {

    setTimeout(()=>{

      let currentRoute = this.router.url.split('/').pop().replace('-desktop', '');
      let width = window.innerWidth;
      let mobileRoute = '/' + currentRoute;
      let desktopRoute;
      let isDesktopRoute = this.router.url.includes('-desktop');

      if(currentRoute){


        const indexSegundaBarra = currentRoute.indexOf('/', 1); // Começa a busca após o primeiro caractere

        // Verifique se a segunda barra existe
        if (indexSegundaBarra !== -1) {
          // Insira "-desktop" antes da segunda barra
          const parte1 = currentRoute.substring(0, indexSegundaBarra);
          const parte2 = currentRoute.substring(indexSegundaBarra);
          desktopRoute =  parte1 + "-desktop" + parte2;
        }
        else{
          desktopRoute = currentRoute+'-desktop';
        }
  
        if(isDesktopRoute){
          this.device = 'desktop';
        }
     
        if (width > 780 && !isDesktopRoute && !currentRoute.includes('login')) {
          this.device = 'desktop';
          this.router.navigate([desktopRoute]);
        }
        else if (isDesktopRoute && width < 780){
          this.device = 'mobile';
          this.router.navigate([mobileRoute]);
        }
        
      }

    }, 800);
  } 
  
  handleChanges() {
    setTimeout(() => {
      let isDesktopRoute = this.router.url.includes('-desktop');
      let currentRoute = this.router.url.replace('-desktop', '');
      let routeDesktop;
  
      if(currentRoute){
        const indexSegundaBarra = currentRoute.indexOf('/', 1); // Começa a busca após o primeiro caractere
  
        // Verifique se a segunda barra existe
        if (indexSegundaBarra !== -1) {
          // Insira "-desktop" antes da segunda barra
          const parte1 = currentRoute.substring(0, indexSegundaBarra);
          const parte2 = currentRoute.substring(indexSegundaBarra);
          routeDesktop =  parte1 + "-desktop" + parte2;
        }
        else{
          routeDesktop = currentRoute+'-desktop';
        }
  
        if ($(window).width() < 780 && isDesktopRoute) {
          this.device = 'mobile';
          this.router.navigate([currentRoute]);
        } else if ($(window).width() > 780 && !isDesktopRoute) {
          this.device = 'desktop';
          this.router.navigate([routeDesktop]);
        }
      }
  
    }, 500);
  }

}
