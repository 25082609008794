import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { Location } from '@angular/common';

@Component({
  selector: 'app-indique',
  templateUrl: './indique.component.html',
  styleUrls: ['./indique.component.scss'],
})
export class IndiqueComponent implements OnInit {

  perfil;
  pintores = [];

  cad_cpf = false;
  user_id;

  //wpp ou qr
  metodo = "wpp";
  is_desktop = false;

  posicao;

  regulamento = false;
  linkIndicacao; 
  copiado = false;

  pintoresConcluidos = JSON.parse(localStorage.getItem('pintoresConcluidos')) || [];

  public modal_ganhou: boolean = false;
  dynamicImageUrl: string;

  constructor(private http: HttpClient, private router: Router, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.perfil = parseInt(localStorage.getItem("perfil"));

    let rota: string = this.route.snapshot.url.toString();

    if (rota.includes('desktop')) {
      this.is_desktop = true;
    }

    if (!this.user_id)
      this.router.navigate(['/login']);

    const url = window.location.hostname;
    console.log(url);
    this.linkIndicacao =(url+'/indicacao/'+this.user_id);
   
    this.obter_pintores();
  }


  obter_ranking() {

    let url;

    if (this.perfil === 2) {
      url = 'participantes_iclub/obter_ranking/2';

      this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
        let indice = data.findIndex(item => item.id === this.user_id);
        this.posicao = indice + 1;
      });

    }

    if (this.perfil === 3) {
      url = 'participantes_iclub/obter_ranking/3';

      this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
        let indice = data.findIndex(item => item.id === this.user_id);
        this.posicao = indice + 1;
      });

    }

  }

  obter_pintores() {
    let url = 'pintores_iclub/obter/' + this.user_id;

    if (this.perfil === 3) {
      url = url + "/true";
    }

    this.http.get<any>(environment.API_URL.concat(url)).subscribe(data => {
      if (data.status == 1) {
        this.pintores = data.pintores;
      }
    });
  }


  atualizar() {

    this.obter_pintores();
    alert("Lista atualizada com sucesso");

  }

  setar_checkboxes() {

    for (let x = 0; x < this.pintores.length; x++) {
      if (this.pintores[x].status == 'ON') {
        $("#v-" + this.pintores[x].id).prop('checked', true);
      }
      if (this.pintores[x].status == 'OFF') {
        $("#v-" + this.pintores[x].id).prop('checked', false);
      }
    }

  }

  share() {
    if (navigator.share !== undefined) {
      navigator.share({
        title: 'Iclub',
        text: 'Faça seu cadastro agora!',
        url: 'https://iclub.iquine.com.br/',
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  modal_cpf(meio) {
    this.metodo = meio;
    this.cad_cpf = true;
  }

  atualizar_status_pintor(event) {

    let id = event.target.id;
    let id_pintor = id.split('-')[1];
    let checked = event.target.checked;
    let status;

    if (checked) {
      status = 'ON';
    }
    else {
      status = 'OFF';
    }

    this.http.post<any>(environment.API_URL.concat('participantes/alterar_status'),
      { id_pintor: id_pintor, id_vendedor: this.user_id, status: status }).subscribe(data => {

        if (data.status == 1) {
          if (status == 'ON')
            alert("Participante ativado com sucesso.");
          if (status == 'OFF')
            alert("Participante inativado com sucesso.");
        }
        else {
          $("#" + id).attr('checked', !checked);
          alert("Não foi possivel realizar essa ação.");

        }
      });
  }

  exibirModalGanhou() {
    this.modal_ganhou = true;
  }

  goback() {
    this.location.back();
  }

  copiarParaAreaDeTrabalho() {
    this.copiado = true;
    // Verifique se há suporte para a execução do comando "copy" na área de transferência
    const inputElement = document.createElement('input');
    inputElement.value = this.linkIndicacao;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  }

}
