import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-promotoresmodal',
  templateUrl: './promotoresmodal.component.html',
  styleUrls: ['./promotoresmodal.component.scss'],
})
export class PromotoresmodalComponent implements OnInit {

  constructor( private modalCtrl: ModalController, private http: HttpClient) { }

  modal = true;
  perfil = parseInt(localStorage.getItem('perfil'));


  regulamento = 'Carregando...'; 
  @Input() isOpen = true; 
  @Output() fechar = new EventEmitter();
  @ViewChild('slides') slides: IonSlides;

  ngOnInit() {
  }
 
  nextSlide() {
    this.slides.slideNext();
  }
  
  prevSlide() {
    this.slides.slidePrev();
  }

  close() {
    console.log('fechou');
    this.fechar.emit({fechou: true});
  }
}

 