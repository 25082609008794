import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent implements OnInit {
  user_id;
  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png"; 
  user_name;
  pontos = 0;
  progress = {
    certificados : 0,
    porcentagem  : 0
  }

  @Input() public pagina: string;
  @Input() public posicao: string;

  @Output() userClicked: EventEmitter<void> = new EventEmitter<void>();
  onUserClick() {
    this.userClicked.emit(); 
  }
  constructor( private location: Location, private http: HttpClient ) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.user_name = localStorage.getItem('userName');

    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    this.obter_progresso();

    let width = window.innerWidth;
    this.obter_dados();
    if(width < 780){
      setInterval(()=>{ this.obter_dados() }, 11000);
    }
  }

  obter_progresso(){
    let formData = new FormData();

    formData.append('token', localStorage.getItem('token'));
    formData.append('id_participante', this.user_id);

    this.http.post<any>(environment.API_URL.concat('participantes_iclub/obter_progresso/'+this.user_id), formData).subscribe(
      data => {
        if(data.status == 1){
          this.progress = data.progresso;
        }
      }
    )
  }

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
    });
  }

  goback(){
    this.location.back();
  }

}
