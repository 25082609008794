import { Component, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
}) @Injectable()

export class HeaderComponent {

  foto_src =  "https://cdn-icons-png.flaticon.com/512/149/149071.png";

  modalNotificacoes = false;
  home = '/home'

  user_id:any;
  token:any;
  quiz:any = [];
  perguntas:any;
  safeURL:any;
  respostas:any = [];
  acertou:any;
  resposta_correta:any;
  tipo: string;
  date: any;

  notificacoes: any = []
  id_notificacao: any;
  titulo_notificacao: any;
  descricao_notificacao: any;
  data_inicio_notificacao: any;
  link_notificacao: any;

  perfil = localStorage.getItem('perfil');

  admin:any;

  quizesLista: any = [];
  notificacoesLista: any = [];

  contadorNotificacoes: number = 0;

  foto_user:any = "https://cdn-icons-png.flaticon.com/512/149/149071.png";  
  quiz_id:any;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private router:Router, private route:ActivatedRoute) {
    this.set_link();
  }

  ngOnInit() {
    
    this.user_id = localStorage.getItem("userId");
    this.foto_user = localStorage.getItem("foto_src0");
  
    this.buscar_quiz();
    this.buscar_notificacoes();
    
    if(localStorage.getItem('foto_src')){
      this.foto_src = localStorage.getItem('foto_src');
    }

    if(localStorage.getItem('admin')){
      this.admin =  localStorage.getItem('admin');
        if(this.admin == '1'){
          this.admin = true;
        }
    }
  }
  
  set_link(){
    const width = window.innerWidth;

    if(width > 760){
      this.home = '/home-desktop';
    }
    else{
      this.home = '/home';
    }
  }

  actionModalNotificacoes() {
    this.modalNotificacoes = !this.modalNotificacoes;

    const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
    const notificacoesNaoVisualizadas = JSON.parse(localStorage.getItem('notificacoes_nao_visualizadas')) || [];

    quizesNaoVisualizados.forEach(quiz => {
      quiz.visualizado = true;
    });

    notificacoesNaoVisualizadas.forEach(notificacao => {
      notificacao.visualizado = true;
    });

    localStorage.setItem('quizes_nao_visualizados', JSON.stringify(quizesNaoVisualizados));
    localStorage.setItem('notificacoes_nao_visualizadas', JSON.stringify(notificacoesNaoVisualizadas));

    this.updateNotificacoesCount();

    this.contadorNotificacoes = 0;
  }


  changeToAdmin(){
    const id = localStorage.getItem('userId');

    this.http.post<any>(environment.API_URL.concat('/login_iclub/login_admin'), {id}).subscribe( (data) => {
      if(data.status == 1){
        localStorage.setItem('userName', data.user.nome);
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('token', data.token);
        localStorage.setItem('perfil', data.user.perfil);
        localStorage.setItem('foto_src', '/assets/iquine-photo.jpeg');

        if (data.user.foto) {
          let foto_src = environment.API_URL.replace('/api/', '/static/participantes/' + data.user.foto);
          localStorage.setItem('foto_src', foto_src);
        }
    
        this.set_link();
        this.router.navigate([this.home]).then( () => { location.reload(); });
      }
      else{
        alert('Houve um erro ao processar sua requisição');
      }
    })

  }

  changeToUser(){
    const nameUser = localStorage.getItem('userName0');
    const idUser = localStorage.getItem('userId0');
    const tokenUser = localStorage.getItem('token0');
    const perfilUser = localStorage.getItem('perfil0');
    const fotoUser = localStorage.getItem('foto_src0');

    localStorage.setItem('userName', nameUser);
    localStorage.setItem('userId', idUser);
    localStorage.setItem('token', tokenUser);
    localStorage.setItem('perfil', perfilUser);
    localStorage.setItem('foto_src', fotoUser);
    
    this.set_link();
    this.router.navigate([this.home]).then( () => { location.reload(); });

  }
 
  buscar_quiz() {
    this.http.get<any>(environment.API_URL.concat('quiz_iclub/index/' + this.user_id)).subscribe(data => {
      this.quiz = data.quiz;
      this.quiz_id = data.quiz.id;
      this.tipo = data.quiz.tipo;
      if(data.quiz){
        this.quiz.data_inicio = new Date(data.quiz.data_inicio);
      }
  
      const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
  
      const quizNaoVisualizado = quizesNaoVisualizados.find(quiz => quiz.id === this.quiz.id && !quiz.respondido);
  
      if (!quizNaoVisualizado) {
        quizesNaoVisualizados.push({
          id: this.quiz.id,
          tipo: this.quiz.tipo,
          visualizado: false,
          date: this.quiz.data_inicio,
          respondido: false
        });
        localStorage.setItem('quizes_nao_visualizados', JSON.stringify(quizesNaoVisualizados));
      }
  
      this.quizesLista = quizesNaoVisualizados.filter(quiz => !quiz.respondido).slice(0, 3);
      this.updateNotificacoesCount();
    });
  }
  


  buscar_notificacoes() {
    this.http.get<any[]>(environment.API_URL.concat('notificacoes_iclub/obter_notificacoes')).subscribe(
      data => {

        if (data && data.length > 0) {
          this.notificacoes = data;

          const notificacoesNaoVisualizadas = JSON.parse(localStorage.getItem('notificacoes_nao_visualizadas')) || [];

          this.notificacoes.forEach(notificacao => {
            this.id_notificacao = notificacao.id;
            this.titulo_notificacao = notificacao.titulo;
            this.descricao_notificacao = notificacao.descricao;
            this.data_inicio_notificacao = notificacao.data_inicio;
            this.link_notificacao = notificacao.link;

            const notificacaoNaoVisualizada = notificacoesNaoVisualizadas.find(notif => notif.id === this.id_notificacao);

            if (!notificacaoNaoVisualizada) {
              notificacoesNaoVisualizadas.push({
                id: this.id_notificacao,
                visualizado: false,
                titulo: this.titulo_notificacao,
                descricao: this.descricao_notificacao,
                data_inicio: this.data_inicio_notificacao,
                link: this.link_notificacao
              });
            }
          });

          localStorage.setItem('notificacoes_nao_visualizadas', JSON.stringify(notificacoesNaoVisualizadas));

          this.notificacoesLista = notificacoesNaoVisualizadas.slice(0, 3);
          this.contadorNotificacoes = notificacoesNaoVisualizadas.filter(notificacao => !notificacao.visualizado).length;

          this.updateNotificacoesCount();
        } else {
          console.error('Nenhuma notificação encontrada na resposta da API.');
        }
      },
      error => {
        console.error('Erro na requisição:', error);
      }
    );
  }
  
  

  updateNotificacoesCount() {
    const quizesNaoVisualizados = JSON.parse(localStorage.getItem('quizes_nao_visualizados')) || [];
    const notificacoesNaoVisualizadas = JSON.parse(localStorage.getItem('notificacoes_nao_visualizadas')) || [];

    this.contadorNotificacoes = quizesNaoVisualizados.filter(quiz => !quiz.visualizado).length +
      notificacoesNaoVisualizadas.filter(notificacao => !notificacao.visualizado).length;
  }

  redirectNotification(link: string): void {
    const isSimpleWord = /^[a-zA-Z]+$/.test(link);
  
    if (isSimpleWord) {
      this.router.navigate(['/' + link]);
    } else {
      window.open(link, '_blank');
    }
  }
  
  

  
  

}
