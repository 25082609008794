import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-header-user-perfil',
  templateUrl: './header-user-perfil.component.html',
  styleUrls: ['./header-user-perfil.component.scss'],
})
export class HeaderUserPerfilComponent implements OnInit {
  @Input() userName: string = '';   
  @Input() userPhoto: string = '';  
  @Input() totalPosts: number = 0;  

  @Output() userClicked: EventEmitter<void> = new EventEmitter<void>();

  onUserClick() {
    this.userClicked.emit(); 
  }
  constructor() { }

  ngOnInit() {}

}
