import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-cuida',
  templateUrl: './cuida.component.html',
  styleUrls: ['./cuida.component.scss'],
})
export class CuidaComponent implements OnInit {
  cursos: any = [];
  usuario: any = {}; // Dados do usuário logado
  user_id;
  user_name;
  cursos_concluidos = [];

  cursoSelecionado:any = [];
  show_certificados: boolean = false;
  
  is_desktop = false;
  regulamento = false;
  loading_top = false;
  constructor(private router:Router, private http: HttpClient, private route:ActivatedRoute) { }

  ngOnInit() {
    
    this.cursos = [ ];

    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_cursos();
  }

  selecionarCurso(curso) {
    console.log(curso);
    this.cursoSelecionado = curso;
  } 

  calcularProgresso() {
    this.cursos.forEach((curso) => {

      const modulos = curso.modulos;
      let aulasFeitas = [];

      modulos.forEach(modulo => { 
        modulo.aulas.forEach(aula => { 
          const a = aula.participantes.filter(participante => participante.id_participante === this.user_id);
          aulasFeitas = aulasFeitas.concat(a);
        });
      });

      const totalAulas = curso.qtd_aulas;
      const porcentagem = (aulasFeitas.length / totalAulas) * 100;
      curso.progresso = Math.round(porcentagem);


      if(curso.progresso == 100){

        let c =  this.cursos_concluidos.some(c => { return c.id == curso.id });
        console.log(c);
        if(!c){
          curso.modulo_final = curso.modulos[curso.modulos.length - 1].nome;
          this.cursos_concluidos.push(curso);
        }
      }

    });
  }

  obter_cursos(){
    this.loading_top = true;
    this.http.get<any>(environment.API_URL.concat('/cuida_iclub/')).subscribe( data => { 

      if(data.status == 1){
        this.cursos = data.cursos;
        this.calcularProgresso();

      }
      this.loading_top = false;
    });
  }

  finalizar_aula($event){

    let curso = this.cursos.find((c)=> { return c.id== $event.modulo.id_curso });
    let modulo = curso.modulos.find((m) => { return m.id== $event.modulo.id });
    let aula = modulo.aulas.find(a => { return a.id == $event.aula.id });
    
    const index_curso = this.cursos.indexOf(curso);
    const index_modulo = curso.modulos.indexOf(modulo);
    const index_aula = modulo.aulas.indexOf(aula);

    this.cursos[index_curso].modulos[index_modulo].aulas[index_aula].participantes.push(
      {
        id_participante: this.user_id, 
        id_aula: aula.id, 
        participante: this.user_name
      }
    );
    
    this.cursoSelecionado = this.cursos[index_curso];
  }

  certificados(){
    this.show_certificados = true;
    this.cursoSelecionado = [];
    console.log(this.cursos_concluidos);
  }

  baixar_certificado(curso){
    this.router.navigate(['/certificado', {'id_curso': curso.id, 'id_participante': this.user_id}]);
  }


}


