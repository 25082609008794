import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Cadastro } from '../../classes/cadastro';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from 'src/app/services/auth.service';
import { Login } from 'src/app/classes/login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import * as $ from 'jquery';

@Component({
    selector: 'app-cadastro-component',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.scss']
})

export class CadastroComponent implements OnInit {

    mostrarSenha = false;
    mostrarSenhaC = false;

    public step = -1;

    public pessoa = 'fisica';
    public cadastro: Cadastro;
    public invalid = false;
    public msgInvalid: string;
    public user: Login;

    public validacao = false;
    public erroEmail = false;

    public conteudo = '';
    public userId = '';

    public cepValido = false;

    regulamento = false;
    aceitoIclub = false;
    carregando = false;

    constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router,
        private rota: ActivatedRoute, private loader: NgxUiLoaderService,
        private auth: AuthService, private modalService: NgbModal, private modalCtrl: ModalController) { }

    ngOnInit() {

        if (localStorage.getItem('userId')) {
            this.router.navigate(['/home']);
        }

        $('.nav-section-main-mobile').removeClass('active-menu');
        this.cadastro = new Cadastro();

        const params = this.rota.snapshot.paramMap;
        const cpf = params.get('cpf');
        const id_indicador = params.get('id');

        if (cpf) {
            this.cadastro.cpf = cpf;
        }

        if (id_indicador) {
            this.cadastro.id_indicador = id_indicador;
        }

    }

    step0() {
        this.invalid = false;
        this.validacao = true;

        if (!this.cadastro.cpf) {
            this.msgInvalid = 'Verifique o preenchimento, o campo CPF é obrigatorio.';
            this.invalid = true;
            return;
        }

        const validacao = this.validarCPF(this.cadastro.cpf);

        if (!validacao) {
            this.msgInvalid = 'Verifique o número do CPF';
            this.invalid = true;
            return;
        }

        this.http.get<any>(environment.API_URL.concat('cadastro_iclub/obter_cpf/' + this.cadastro.cpf)).subscribe(data => {

            if (data.status === 1) {
                this.router.navigate(['/login', { cpf: this.cadastro.cpf }]);
            }

            else if (data.status === 2) {

                this.cadastro.nome = data.legado.nome;
                this.cadastro.aniversario = data.legado.aniversario;
                this.cadastro.cpf = data.legado.cpf;
                this.cadastro.email = data.legado.email;

                if (data.legado.celular1) {
                    this.cadastro.celular = data.legado.celular1;
                }
                else if (data.legado.celular2) {
                    this.cadastro.celular = data.legado.celular2;
                }

                this.step = 1;
            }

            else {
                this.step = 0;
            }

        });

    }

    step1() {

        this.invalid = false;
        this.validacao = true;
        this.erroEmail = false;


        const validacao = this.validarCPF(this.cadastro.cpf);

        if (!validacao) {
            this.msgInvalid = 'Verifique o número do CPF';
            this.invalid = true;
            return;
        }


        if (!this.cadastro.nome) {
            this.msgInvalid = 'Verifique o preenchimento, o campo NOME é obrigatorio.';
            this.invalid = true;
            return;
        }

        const nomes = this.cadastro.nome.split(' ');

        if (nomes.length < 2) {
            this.msgInvalid = 'Verifique o preenchimento, Insira seu Nome Completo.';
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.email) {
            this.msgInvalid = 'Verifique o preenchimento, o campo EMAIL é obrigatorio.';
            this.invalid = true;
            return;
        }

        else if (this.cadastro.email.indexOf('@') < 0 || this.cadastro.email.indexOf('.') < 0 || this.cadastro.email.length < 6) {
            this.msgInvalid = 'Verifique o formato do EMAIL utilizado.';
            this.erroEmail = true;
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.celular) {
            this.msgInvalid = 'Verifique o preenchimento, o campo Whatsapp é obrigatorio.';
            this.invalid = true;
            return;
        }

        if (this.cadastro.nome.length < 4) {
            this.invalid = true;
            this.msgInvalid = 'O nome informado parece inválido';
            return;
        }

        else if (this.cadastro.email.length < 4) {
            this.invalid = true;
            this.msgInvalid = 'O e-mail informado parece inválido';
            return;
        }

        else if (this.cadastro.celular.length < 11) {
            this.msgInvalid = 'Verifique o número do celular';
            this.invalid = true;
            return;
        }

        if (this.cadastro.sexo.length === 0) {
            this.msgInvalid = 'Verifique o preenchimento, o campo GÊNERO é obrigatorio.';
            this.invalid = true;
            return;
        }

        if (this.cadastro.aniversario.length < 8) {
            this.msgInvalid = 'Verifique o preenchimento, o campo ANIVERSARIO é obrigatorio.';
            this.invalid = true;
            return;
        }

        // Obtém a data atual
        const dataAtual = new Date();

        // Obtém o mês e ano de aniversário do cadastro
        const mes = parseInt(this.cadastro.aniversario.substring(2, 4));
        const ano = parseInt(this.cadastro.aniversario.substring(4, 8));

        // Verifica o formato do campo ANIVERSARIO
        if (mes > 12 || mes === 0) {
            this.msgInvalid = "Verifique o formato do campo ANIVERSARIO.";
            this.invalid = true;
            return;
        }

        // Calcula a idade com base na data de aniversário e data atual
        const idade = dataAtual.getFullYear() - ano - (dataAtual.getMonth() < mes - 1 || (dataAtual.getMonth() === mes - 1 && dataAtual.getDate() < parseInt(this.cadastro.aniversario.substring(0, 2))) ? 1 : 0);
        console.log(idade);
        // Verifica se a idade é menor que 18 anos
        if (idade < 18) {
            this.msgInvalid = "Você precisa ter +18 anos para se cadastrar.";
            this.invalid = true;
            return;
        }



        this.http.post<any>(environment.API_URL.concat('cadastro_iclub/obter_email/'), {
            email: this.cadastro.email
        }).subscribe((data: any) => {

            if (data.status === 1) {
                alert('E-mail já cadastrado no sistema');
                this.invalid = true;
                return;
            }

            else {
                this.http.get<any>(environment.API_URL.concat('cadastro_iclub/obter_cpf/' + this.cadastro.cpf)).subscribe(response => {
                    if (response.status === 1) {
                        alert('CPF já cadastrado');
                        this.invalid = true;
                        return;
                    }

                    else {

                        this.step = 2;

                    }
                });

            }

        });

    }

    step2() {
        this.loader.start();
        this.invalid = false;
        this.validacao = true;

        if (this.cadastro.rua.length < 3) {
            this.msgInvalid = 'Verifique o preenchimento, o campo RUA é obrigatorio.';
            this.invalid = true;
            return;
        }

        if (!this.cadastro.numero) {
            this.msgInvalid = 'Verifique o preenchimento, o campo NÚMERO é obrigatorio.';
            this.invalid = true;
            return;
        }

        /* if (this.cadastro.cep.length < 8) {
            this.msgInvalid = 'Verifique o preenchimento, o campo CEP é obrigatorio.';
            this.invalid = true;
            return;
        } */

        if (this.cadastro.cidade.length < 3) {
            this.msgInvalid = 'Verifique o preenchimento, o campo CIDADE é obrigatorio.';
            this.invalid = true;
            return;
        }

        if (this.cadastro.estado.length < 2) {
            this.msgInvalid = 'Verifique o preenchimento, o campo ESTADO é obrigatorio.';
            this.invalid = true;
            return;
        }

        if (this.cadastro.bairro.length === 0) {
            this.msgInvalid = 'Verifique o preenchimento, o campo BAIRRO é obrigatorio.';
            this.invalid = true;
            return;
        }


        this.validacao = false;
        $('html, body').animate({
            scrollTop: 0
        }, 500);

        this.step = 3;
    }

    viacep() {

        if (this.cadastro.cep.length < 8) {
            this.cepValido = false;
            return false;
        }

        this.cadastro.rua = 'aguarde...';
        this.http.get<any>('https://viacep.com.br/ws/' + this.cadastro.cep + "/json").subscribe((end) => {
            if (end.erro) {
                this.cepValido = false;
                this.msgInvalid = "CEP inválido.";
                this.invalid = true;
            } else {
                this.cadastro.rua = end.logradouro;
                this.cadastro.complemento = end.complemento;
                this.cadastro.bairro = end.bairro;
                this.cadastro.cidade = end.localidade;
                this.cadastro.estado = end.uf;
                this.cepValido = true;
            }
        }, error => {
            this.cepValido = false;
            this.msgInvalid = "Erro ao buscar CEP.";
            this.invalid = true;
        });
    }

    step3() {
        this.invalid = false;
        this.validacao = true;
        this.carregando = true;
        this.erroEmail = false;

        if (!this.cadastro.nome) {
            this.msgInvalid = 'Verifique o preenchimento, o campo NOME é obrigatorio.';
            this.invalid = true;
            this.carregando = false;
            return;
        }

        else if (!this.cadastro.cpf) {
            this.msgInvalid = 'Verifique o preenchimento, o campo CPF é obrigatorio.';
            this.carregando = false;
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.email) {
            this.msgInvalid = 'Verifique o preenchimento, o campo EMAIL é obrigatorio.';
            this.carregando = false;
            this.invalid = true;
            return;
        }

        else if (this.cadastro.email.indexOf('@') < 0 || this.cadastro.email.indexOf('.') < 0 || this.cadastro.email.length < 6) {
            this.msgInvalid = 'Verifique o formato do EMAIL utilizado.';
            this.erroEmail = true;
            this.carregando = false;
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.celular) {
            this.msgInvalid = 'Verifique o preenchimento, o campo CELULAR é obrigatorio.';
            this.carregando = false;
            this.invalid = true;
            return;
        }

        if (this.cadastro.nome.length < 4) {
            this.carregando = false;
            this.invalid = true;
            this.msgInvalid = 'O nome informado parece inválido';
            return;
        }

        else if (this.cadastro.email.length < 4) {
            this.carregando = false;
            this.invalid = true;
            this.msgInvalid = 'O e-mail informado parece inválido';
            return;
        }

        else if (this.cadastro.celular.length < 11) {
            this.msgInvalid = 'Verifique o número do celular';
            this.carregando = false;
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.sexo) {
            this.msgInvalid = 'Selecione seu genero';
            this.carregando = false;
            this.invalid = true;
            return;
        }


        if (!this.validarSenha(this.cadastro.senha)) {
            this.carregando = false;
            this.invalid = true;
            return;
        }

        else if (!this.cadastro.aceito_term) {
            this.msgInvalid = 'Você precisa aceitar os termos do regulamento para prosseguir com o cadastro';
            this.invalid = true;
            this.carregando = false;
            return;
        }

        this.http.post<any>(environment.API_URL.concat('cadastro_iclub/obter_email/'), {
            email: this.cadastro.email
        }).subscribe((data: any) => {

            if (data.status === 1) {
                alert('E-mail já cadastrado no sistema');
                this.router.navigate(['/login']);
                return;
            }

            else {

                const validacao = this.validarCPF(this.cadastro.cpf);

                if (!validacao) {
                    this.msgInvalid = 'Verifique o número do CPF';
                    this.invalid = true;
                    this.carregando = false;

                    return;
                }

                this.http.get<any>(environment.API_URL.concat('cadastro_iclub/obter_cpf/' + this.cadastro.cpf)).subscribe(response => {
                    if (response.status == 1) {
                        alert('CPF já cadastrado');
                        this.invalid = true;
                        this.carregando = false;
                        return;
                    }

                    else {

                        this.http.post<any>(environment.API_URL.concat('cadastro_iclub/cadastrar'), this.cadastro).subscribe(res => {
                            if (res.status == 1) {
                                //localStorage.setItem('id_confirmacao', res.id);
                                //this.router.navigate(['/confirmacao']);
                                localStorage.setItem('userId', res.id);
                                localStorage.setItem('token', res.token);
                                localStorage.setItem('perfil', res.perfil);
                        
                                if (parseInt(res.perfil, 10) === 1) {
                                    this.router.navigate(['/primeiro-acesso']);
                                }
                                else {
                                    this.router.navigate(['/promotores']);
                                }
                            }
                            else {
                                alert('ops! houve um erro ao processar essa requisição, tente novamente mais tarde');
                            }
                        });
                    }
                });



            }

        });
    }

    validarCPF(strCPF) {

        if (this.cadastro.cpf.length < 11) {
            return false;
        }

        let soma;
        let resto;
        soma = 0;

        if (strCPF === '00000000000') {
            return false;
        }

        for (let i = 1; i <= 9; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i); }
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) { resto = 0; }
        if (resto !== parseInt(strCPF.substring(9, 10), 10)) {
            return false;
        }

        soma = 0;
        for (let i = 1; i <= 10; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i); }
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) { resto = 0; }
        if (resto !== parseInt(strCPF.substring(10, 11), 10)) {
            return false;
        }

        return true;

    }

    validarSenha(strSenha) {
        // Verifica se a senha tem pelo menos 6 dígitos
        if (this.cadastro.senha.length < 6) {
            this.msgInvalid = 'Sua senha deve ter ao menos 6 caracteres';
            return false;
        }

        else if (this.cadastro.senha !== this.cadastro.senha_c) {
            this.msgInvalid = 'As senhas não conferem';
            return false;
        }

        // Verifica se a senha contém pelo menos um caractere especial
        const caracteresEspeciais = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!caracteresEspeciais.test(strSenha)) {
            this.msgInvalid = 'A senha deve ter ao menos 1(um) caractere especial';
            return false;
        }

        // Verifica se a senha contém pelo menos uma letra maiúscula
        const letraMaiuscula = /[A-Z]/;
        if (!letraMaiuscula.test(strSenha)) {
            this.msgInvalid = 'A senha deve ter ao menos 1(uma) letra maiúscula';
            return false;
        }


        // Verifica se a senha contém pelo menos um numero
        const numero = /[0-9]/;
        if (!numero.test(strSenha)) {
            this.msgInvalid = 'A senha deve ter ao menos 1(um) número';
            return false;
        }
        // Se todas as condições foram atendidas, a senha é válida
        return true;
    }

    validarAtivacao(documento, token) {

        this.http.post<any>(environment.API_URL.concat('cadastro_iclub/buscar_ativacao'), { token, documento })
            .subscribe(data => {

                if (data.status === 0) {
                    alert(data.erro);
                    return;
                }

                this.cadastro = data.user;
                this.cadastro.token_cadastro = token;

            }, error => {
                console.log(error)
            });

    }

    abrir_regulamento() {
        this.regulamento = true;
    }


    gerar_senha_aleatoria(length) {
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '!@#$%^&*()_-+=[]{}|;:,.<>?';

        const allCharacters = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;
        const randomStringArray = [];

        // Adiciona pelo menos um caractere de cada tipo
        randomStringArray.push(this.getRandomCharacter(uppercaseLetters));
        randomStringArray.push(this.getRandomCharacter(lowercaseLetters));
        randomStringArray.push(this.getRandomCharacter(numbers));
        randomStringArray.push(this.getRandomCharacter(specialCharacters));

        // Completa o restante da string aleatória
        for (let i = 4; i < length; i++) {
            randomStringArray.push(this.getRandomCharacter(allCharacters));
        }

        // Embaralha o array para obter uma ordem aleatória
        randomStringArray.sort(() => Math.random() - 0.5);

        // Junta todos os caracteres em uma única string
        const randomString = randomStringArray.join('');
        this.cadastro.senha = randomString;
        this.cadastro.senha_c = randomString;

        this.mostrarSenha = true;
        this.mostrarSenhaC = true;
    }

    getRandomCharacter(characters) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        return characters.charAt(randomIndex);
    }


}
