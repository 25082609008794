import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'contagem-regressiva',
  templateUrl: './contagem_regressiva.component.html',
  styleUrls: ['./contagem_regressiva.component.css']
})

export class ContagemRegressivaComponent implements OnInit {
  
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  @Input() data_fim: any;
  dateTo: any;
  constructor() { }

  
  public config = {
    leftTime : 900, 
    format : 'HH:m:s'
  }

  ngOnInit(): void {
 
    this.config.leftTime = this.data_fim / 1000;

    //this.countdown.begin()
  }

  handleEvent(e){
    console.log(e);
  }

}
