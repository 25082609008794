import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-component',
  templateUrl: 'component.component.html',
  styleUrls: ['component.component.css'],
})
export class AppComponent {
  @Input()
  Text: string = 'Acessar extrato'
  @Input()
  text: string = 'Saldo de Pontos '
  @Input()
  image_src: string = '/assets/grupo%202096-200h.png'
  @Input()
  image_src1: string = '/assets/external/icon-200h.png'
  @Input()
  Text1: string = 'Ranking'
  @Input()
  image_alt: string = 'image'
  @Input()
  image_alt1: string = 'image'
  @Input()
  text1: string = 'Moedas Bem'
  @Input()
  rootClassName: string = ''
  raw0mp0: string = ' '
  rawhwpu: string = ' '
  constructor() {}
}
