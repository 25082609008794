import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-regulamentomodal',
  templateUrl: './regulamentomodal.component.html',
  styleUrls: ['./regulamentomodal.component.scss'],
})
export class RegulamentomodalComponent implements OnInit {

  constructor( private modalCtrl: ModalController, private http: HttpClient) { }

  perfil = parseInt(localStorage.getItem('perfil'));
  regulamento = 'Carregando...'; 
  @Input() isOpen = true; 
  @Output() fechar = new EventEmitter();

  ngOnInit() {

    this.obter_regulamento();

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
    if(changes.isOpen.currentValue == true){
      this.isOpen = true;
    }
    else{
      this.isOpen = false;
    }
    
  }

  obter_regulamento(){

    if(!this.perfil){
      return;
    }

    this.http.get<any>(environment.API_URL.concat('regulamento_iclub/index/'+this.perfil)).subscribe( data => {
      if(data.status == 1){
        this.regulamento = data.content;
      }
      else{
        alert('houve um erro ao obter o regulamento');
      }
    })
  }

  close() {
    this.fechar.emit({fechou: true});
  }

}

 