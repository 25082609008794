import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-treinamentos',
  templateUrl: './treinamentos.component.html',
  styleUrls: ['./treinamentos.component.scss'],
})
export class TreinamentosComponent implements OnInit {
  usuario: any = {}; // Dados do usuário logado
  user_id;
  user_name;
  perfil = parseInt(localStorage.getItem('perfil'));


  treinamentos:any = [];
  

  cursoSelecionado:any = [];
  treinamentoSelecionado:any = [];
  show_treinamentos: boolean = false;
  
  is_desktop = false;
  regulamento = false;
  loading_top = false;
  constructor(private router:Router, private http: HttpClient, private route:ActivatedRoute) { }

  ngOnInit() {
    
    console.log(this.treinamentos + 'treinamentos');
    this.user_id = localStorage.getItem('userId');
    this.user_name = localStorage.getItem('userName');
    
    if(!this.user_id){
      this.router.navigate(['/login']);
    }

    if( this.perfil !== 1 ){
      this.router.navigate(['/promotores']);
    }

    let rota:string = this.route.snapshot.url.toString();

    if(rota.includes('desktop')){
      this.is_desktop = true;
    }

    this.obter_treinamentos();
  }

  selecionarCurso(curso) {
    console.log(curso);
    this.cursoSelecionado = curso;
  } 

   obter_treinamentos(){
    this.loading_top = true;
    this.http.get<any>(environment.API_URL.concat('/treinamentos_iclub/')).subscribe( data => { 

      if(data.status == 1){
        this.treinamentos = data.treinamentos;

      }
      this.loading_top = false;
    });
  } 


}