import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckboxCustomEvent } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';

@Component({
  selector: 'app-cad-cpf',
  templateUrl: './cadastro_cpf.component.html',
  styleUrls: ['./cadastro_cpf.component.scss'],
})

export class CadastroCpfComponent implements OnInit {

  constructor( private router : Router, private formBuilder: FormBuilder, private http:HttpClient) { }

  perfil;
  canDismiss = false;
  presentingElement = null;
  @Input() isOpen;
  @Input() metodo;
  @Output() fechar = new EventEmitter();

  step = 1;
  data = [{ cpf: '' }]

  form: FormGroup = this.formBuilder.group({
    contacts: this.formBuilder.array(this.data.map(
      contact => this.formBuilder.group(contact)
    ))
  });

  get contacts(): FormArray {
    return this.form.get('contacts') as FormArray;
  }

  addContact() {
    this.contacts.push(this.formBuilder.group({
      cpf: null
    }))
  }


  ngOnInit() {
    this.perfil = localStorage.getItem('perfil');
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes);

    if(changes.isOpen.currentValue == false){
      this.isOpen = false;
    }

  }

  onTermsChanged(event: Event) {
    const ev = event as CheckboxCustomEvent;
    this.canDismiss = ev.detail.checked;
  }

  close() {
    this.form.reset();
    this.step = 1;
    this.fechar.emit({ fechar: true });
  }

  close_routing(route){
    this.fechar.emit({fechar : true});

    setTimeout(()=>{
      this.router.navigate([route]);
    },500);
  }

  cadastrar(){

    let valid = false;

    for(let i = 0; i < this.contacts.value.length; i++){
      console.log(this.contacts.value[i].cpf);
  
      if(this.validarCPF(this.contacts.value[i].cpf)){
        valid = true;
      }

    }

    if(!valid){
      alert("CPF invalido, favor verifique os campos");
      return;
    }

    this.cadastrar_pintor();
  
  }


  cadastrar_pintor(){
    let user_id = localStorage.getItem('userId');

    let request;
  
    request = { 'cpfs': this.contacts.value, 'id_indicador' : user_id }

    this.http.post<any>(environment.API_URL.concat('cadastro_iclub/precadastrar'),
    request).subscribe((data)=>{
      if(data.status == 1){
        if(this.metodo == 'qr'){
          this.step = 2;
        }
        if(this.metodo == 'wpp'){
          $("#disparar_wpp").trigger('click');
          alert('Pré cadastro realizado com sucesso');
          this.close();
        }
      }
      else if(data.status == 2){
        alert("O participante já está cadastrado como "+data.perfil+" no programa Iquine Iclub");
      }
      else{
        alert('Houve um erro, tente novamente em alguns instantes');
      }
    });
  }

  validarCPF(strCPF) {

    if(!strCPF){
      return false;
    }

    if (strCPF.length < 11) {
        return false;
    }

      var Soma;
      var Resto;
      Soma = 0;

      if (strCPF == "00000000000") {
          return false;
      }

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) {
          return false;
      }

      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) {
          return false;
      }

      return true;

  }

}
