import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})

export class VideoComponent implements OnInit {

  @Input() curso: any = [];
  @Output() finalizar = new EventEmitter();

  moduloAtivo = null;
  aulaSelecionada: any = null;
  respostas = [];
  acertou: any;
  etapa = 1;
  safeUrl:SafeResourceUrl;
  loading = false;
  user_id;
  bloqueado = false; 
  concluido = false;

  relevance: string;
  respostaEnviada: boolean = false;


  public modal_ganhou: boolean = false;

  constructor(private sanitizer: DomSanitizer, private http:HttpClient) { }

  ngOnInit() {
    this.user_id = localStorage.getItem("userId");
    this.configurar_aulas();
    this.moduloAtivo = this.curso.modulos[0];
    this.obter_dados();
  }

  configurar_aulas() {
     for (const modulo of this.curso.modulos) {
      for (const aula of modulo.aulas) {

        if (aula.url_content) {
          aula.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(aula.url_content);
        }

        const respondeu = aula.participantes.find((reg) => reg.id_participante === this.user_id);
        if (respondeu) {
          aula.statusText = 'Concluído';
        }
        else{
          aula.statusText = 'Pendente';
        }
      }
      
    } 
  }


  openAula(aula) {
    this.concluido = false;
    this.aulaSelecionada = aula; 
    this.bloqueado = false; // Assume que não está bloqueado até verificar
  
    // Verifica imediatamente se já respondida
    this.verificarResposta();
  }
  
  
    

  verificarResposta() {
    if (!this.aulaSelecionada) {
      console.error('Aula não selecionada');
      return;
    }
  
    const id_aula = this.aulaSelecionada.id;
    const id_participante = this.user_id;
  
    this.http.get<any>(`${environment.API_URL}/cuida_iclub/verificar_resposta_usuario`, {
      params: { id_aula, id_participante }
    }).subscribe({
      next: (data) => {
        if (data.status === 1) {
          this.respostaEnviada = true;
        } else {
          this.respostaEnviada = false;

        }
      },        
      error: (error) => {
        console.error('Erro ao verificar resposta:', error);
        this.respostaEnviada = false;
      }
    });
  }
  

  reset_timer(){
    this.etapa = 1;
    this.bloqueado = true;
    setTimeout(()=>{
      this.bloqueado = false;
    }, 1000);

  }

  set_modulo(modulo){
    this.etapa = 1;
    this.moduloAtivo = modulo;
    this.aulaSelecionada = null;
  }


  pontos;
  moedas;
  pontos_ganhos;
  moedas_ganhos;
  loaded_data;

  
  enviarResposta(id_aula: number, id_participante: number, resposta: string): void {
    this.http.post<any>(`${environment.API_URL}/cuida_iclub/registrar_resposta`, {id_aula, id_participante, resposta, status: 1})
      .subscribe({
        next: (data) => {
          if (data.status === 1) {
            this.pontos_ganhos = data.pontos; 
            this.animacao_saldo();
            this.obter_dados(); 
            this.verificarResposta();
          }
        },
        error: (error) => {
          console.error('Erro ao enviar resposta:', error);
        }
      });
  }
  

  obter_dados() {
    this.http.get<any>(environment.API_URL.concat('pontos_iclub/index?id_participante=' + this.user_id)).subscribe(data => {
      this.pontos = data.pontos;
      this.moedas = data.moedas;
      this.loaded_data = true;
    });
  }

  animacao_saldo() {
    this.modal_ganhou = true;
    setTimeout(() => { this.modal_ganhou = false; }, 4000);
  }

  atualizar_saldos($event) {
    this.obter_dados(); 
  }

}
