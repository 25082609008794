import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { Navbar } from './navbar/navbar.component'
import { Bloco } from './bloco/bloco.component'
import { SocialBar } from './social-bar/social-bar.component'
import { NavigationLinks } from './navigation-links/navigation-links.component'
import { AppComponent } from './component/component.component'

@NgModule({
  declarations: [
    Navbar,
    Bloco,
    SocialBar,
    NavigationLinks,
    AppComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [Navbar, Bloco, SocialBar, NavigationLinks, AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
